import styled from '@emotion/styled';
import { differenceInHours } from 'date-fns/differenceInHours';
import { differenceInMinutes } from 'date-fns/differenceInMinutes';
import { differenceInSeconds } from 'date-fns/differenceInSeconds';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { MustDropColors } from './must-drop-colors';
import { parseInputDate } from './parseInputDate';

const Container = styled.span({
  color: MustDropColors.TextHighlight,
});

type TimeLeft = {
  hours: number;
  minutes: number;
  seconds: number;
};

type TimeoutId = ReturnType<typeof setTimeout>;

const getTimeLeft = (date1: Date, date2: Date): TimeLeft => {
  const [first, second] = [date1, date2].sort((a, b) => a.getTime() - b.getTime());
  return {
    hours: differenceInHours(second, first) % 24,
    minutes: differenceInMinutes(second, first) % 60,
    seconds: differenceInSeconds(second, first) % 60,
  };
};

const format24h = (tl: TimeLeft): string => {
  return [`${tl.hours}`, `${tl.minutes}`, `${tl.seconds}`]
    .map((entry) => (entry.length < 2 ? `0${entry}` : entry))
    .join(':');
};

type Props = {
  time: string;
};

export const DropCountdown: FC<Props> = ({ time }) => {
  const tl = getTimeLeft(parseInputDate(time), new Date());
  const [timeLeft, setTimeLeft] = useState(tl);

  useEffect(() => {
    let isMounted = true;
    let tickId: TimeoutId;

    const updateTime = () => {
      const now = new Date();
      const newTimeLeft = getTimeLeft(parseInputDate(time), now);

      if (isMounted) {
        setTimeLeft(newTimeLeft);
        tickId = setTimeout(updateTime, 1001 - (now.getTime() % 1000));
      }
    };

    updateTime();

    return () => {
      isMounted = false;
      clearTimeout(tickId);
    };
  }, [time]);

  return <Container>{format24h(timeLeft)}</Container>;
};
