import type { ComponentProps, FC } from 'react';
import { Image } from '@pafcloud/base-components';

type BettingSportsIconProps = Omit<ComponentProps<typeof Image>, 'src' | 'loader'> & {
  termKey: string;
  color?: string;
};

export const BettingSportsIcon: FC<BettingSportsIconProps> = ({ termKey, color = '#ffffff', ...imageProps }) => {
  return (
    <Image
      {...imageProps}
      src={`https://paf.se/api/kambi-sports-icon?termKey=${encodeURIComponent(termKey.toLowerCase())}&fill=${encodeURIComponent(
        color,
      )}`}
      unoptimized
    />
  );
};
