/**
 * @generated SignedSource<<9305ad0b1e97714764f6b8dee5bf52e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PokerLauncherItem_data$data = {
  readonly backgroundUrl: string;
  readonly enablePopUpReminder: string | null;
  readonly heading: string | null;
  readonly info: string | null;
  readonly playInBrowserButton: string;
  readonly tagline: string | null;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"PokerAppStoreLink_data" | "PokerDownloadButton_data" | "PokerGooglePlayLink_data">;
  readonly " $fragmentType": "PokerLauncherItem_data";
};
export type PokerLauncherItem_data$key = {
  readonly " $data"?: PokerLauncherItem_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"PokerLauncherItem_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PokerLauncherItem_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "heading",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tagline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "info",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backgroundUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "playInBrowserButton",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enablePopUpReminder",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PokerDownloadButton_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PokerAppStoreLink_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PokerGooglePlayLink_data"
    }
  ],
  "type": "PokerLauncher",
  "abstractKey": null
};

(node as any).hash = "1dfa45c46545a0a6a498a45b90e7f65a";

export default node;
