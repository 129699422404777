/**
 * @generated SignedSource<<a815fc74010dfd5adfbdfd0d1be18a2a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SanityPage_page$data = {
  readonly blocks: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"PageBlockRenderer_blocks">;
  } | null>;
  readonly metadata: {
    readonly canonical: string | null;
    readonly description: string | null;
    readonly robots: string | null;
    readonly title: string | null;
  } | null;
  readonly requiredAge: number | null;
  readonly seo: {
    readonly disableInvisibleHeading: boolean | null;
    readonly headingText: string | null;
  } | null;
  readonly title: string;
  readonly " $fragmentType": "SanityPage_page";
};
export type SanityPage_page$key = {
  readonly " $data"?: SanityPage_page$data;
  readonly " $fragmentSpreads": FragmentRefs<"SanityPage_page">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SanityPage_page",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiredAge",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PageSEO",
      "kind": "LinkedField",
      "name": "seo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "disableInvisibleHeading",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "headingText",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PageMetadata",
      "kind": "LinkedField",
      "name": "metadata",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canonical",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "robots",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "blocks",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PageBlockRenderer_blocks"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Page",
  "abstractKey": null
};
})();

(node as any).hash = "5c024dcd350635d3d7fd7dc659d103b7";

export default node;
