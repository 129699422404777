import type { FC } from 'react';
import { useFormatAmount } from '@pafcloud/react-hook-utils';
import { AmountText } from './shared';
import { useTween } from './useTween';

type DropAmountProps = {
  amount: number;
};

export const DropAmount: FC<DropAmountProps> = ({ amount }) => {
  const formatAmount = useFormatAmount();
  const animatedAmount = useTween(amount);

  return <AmountText>{formatAmount(animatedAmount)}</AmountText>;
};
