import styled from '@emotion/styled';
import type { FC, ReactNode } from 'react';
import { keyframes } from '@emotion/react';
import { Breakpoint, Color, DynamicValues, FontHeadingSize, getBrandBorder, ZIndex } from '@pafcloud/style';
import { useTranslation } from '@pafcloud/i18n';
import { ScrollShadow } from '../scroll-indicator/scroll-shadow';
import { Button } from '../button';
import { TAB_POPUP_HEIGHT, TAB_POPUP_WIDTH } from './groupedTabContants';

const ModalHeader = styled.header({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexGrow: 0,
  flexShrink: 0,
  height: 68,
  padding: '8px 24px 8px 16px',
  fontSize: FontHeadingSize.Small,
  fontWeight: 100,
  lineHeight: 1,
  borderBottom: `1px solid ${Color.Surface.Floating.Dimmed}`,
  color: Color.BodyText,

  [Breakpoint.LaptopOrLarger]: {
    display: 'none',
  },
});

const ModalTitle = styled.h2({
  margin: 0,
  fontSize: FontHeadingSize.Small,
  fontWeight: 300,
  whiteSpace: 'nowrap',
});

const ModalBody = styled.div({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',

  [Breakpoint.Phone]: {
    overflowY: 'auto',
  },
});

const ModalFooter = styled.div({
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 0,
  flexShrink: 0,
  padding: 12,
  borderTop: `1px solid ${Color.Surface.Floating.Dimmed}`,
  zIndex: ZIndex.Tray + 1,

  [Breakpoint.LaptopOrLarger]: {
    display: 'none',
  },
});

const ModalScroll = styled(ScrollShadow)({
  flexGrow: 1,
  height: 128, //flex hack to no grow beyond container.

  [Breakpoint.LaptopOrLarger]: {
    flexGrow: 0,
    background: 'none',
    overflow: 'unset',
    height: 'unset',
  },
});

const animateIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    transform: 'translateY(12px)',
  },
});

const Base = styled.div<{ position: string }>((props) => ({
  position: 'fixed',
  zIndex: ZIndex.Tray,
  width: '100%',
  top: DynamicValues.GamePauseHeight,
  left: 0,
  height: `calc(100% - ${DynamicValues.GamePauseHeight})`,
  background: Color.Surface.Floating.Background,
  ...getBrandBorder(),

  [Breakpoint.LaptopOrLarger]: {
    position: 'absolute',
    zIndex: ZIndex.GroupedTab,
    boxShadow: Color.Elevation.Level2,
    height: 'auto',
    overflowY: 'auto',
    width: TAB_POPUP_WIDTH,
    maxHeight: TAB_POPUP_HEIGHT,
    borderRadius: 3,

    background: Color.Surface.Base.Background,
    outline: 'none',
    animation: `${animateIn} 0.1s forwards`,
    top: props.position.includes('bottom') ? 35 : 'unset',
    left: props.position.includes('Right') ? 0 : 'unset',
    bottom: props.position.includes('top') ? 60 : 'unset',
    right: props.position.includes('Left') ? 0 : 'unset',
  },
}));

const GroupedTabDropdown = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

type GroupedTabPopupProps = {
  title: string;
  onClose: () => void;
  position: string;
  children?: ReactNode;
};

export const GroupedTabPopup: FC<GroupedTabPopupProps> = ({ onClose, title, children, position }) => {
  const { t } = useTranslation('common');

  return (
    <Base position={position}>
      <GroupedTabDropdown>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <ModalScroll>{children}</ModalScroll>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" type="button" size="default" onClick={onClose}>
            {t('close')}
          </Button>
        </ModalFooter>
      </GroupedTabDropdown>
    </Base>
  );
};
