import type { FC, ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { BlockContainer, TableBlock } from '@pafcloud/block-components';
import { RichTextBlock } from '@pafcloud/rich-text';
import { removeNullValues } from '@pafcloud/collection-utils';
import AccordionBlock from '../accordion-block/AccordionBlock';
import DepositFormBlock from '../deposit-form-block/DepositFormBlock';
import BonusTermsBlock from '../bonus-terms-block/BonusTermsBlock';
import GameShortcutBlock from '../game-shortcut/GameShortcutBlock';
import LinkContainerBlock from '../link-container-block/LinkContainerBlock';
import type { ContainerBlock_block$key } from './__generated__/ContainerBlock_block.graphql';

// Add blocks here to allow them to appear inside container-block,
// also add them to apps/sanity/src/definitions/block/types/containerBlock.ts

const blockComponents = {
  AccordionBlock,
  BonusTermsBlock,
  DepositFormBlock,
  GameShortcutBlock,
  LinkContainerBlock,
  RichTextBlock,
  TableBlock,
};

const blockFragment = graphql`
  fragment ContainerBlock_block on ContainerBlock {
    blocks {
      __typename
      ...AccordionBlock_block
      ...BonusTermsBlock_block
      ...DepositFormBlock_block
      ...GameShortcutBlock_block
      ...LinkContainerBlock_block
      ...RichTextBlock_block
      ...TableBlock_block
    }
    blockLayout
    hasBackground
    hasBackLink
  }
`;

const getBlockComponent = (type: string, components: typeof blockComponents) => {
  if (type in components) {
    return components[type as keyof typeof components];
  }
  return null;
};

type Props = {
  block: ContainerBlock_block$key;
  blockRenderer: ReactNode;
};

const ContainerBlockRenderer: FC<Props> = (props) => {
  const { blocks, blockLayout, hasBackground, hasBackLink } = useFragment(blockFragment, props.block);

  return (
    <BlockContainer layout={blockLayout} hasBackground={hasBackground} hasBackLink={hasBackLink}>
      {removeNullValues(blocks).map((block, index) => {
        const Component = getBlockComponent(block.__typename, blockComponents);
        if (Component) {
          return <Component key={index} block={block} blockRenderer={props.blockRenderer} />;
        }
        return null;
      })}
    </BlockContainer>
  );
};

export default ContainerBlockRenderer;
