/**
 * @generated SignedSource<<5252b5f37dfde642a4cb54701059c83b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Hero_block$data = {
  readonly __typename: "BasicHero";
  readonly " $fragmentSpreads": FragmentRefs<"BasicHero_data">;
  readonly " $fragmentType": "Hero_block";
} | {
  readonly __typename: "BettingHero";
  readonly " $fragmentSpreads": FragmentRefs<"BettingHero_data">;
  readonly " $fragmentType": "Hero_block";
} | {
  readonly __typename: "DiscoverWeeklyHero";
  readonly " $fragmentSpreads": FragmentRefs<"DiscoverWeeklyHero_data">;
  readonly " $fragmentType": "Hero_block";
} | {
  readonly __typename: "GameItemHero";
  readonly " $fragmentSpreads": FragmentRefs<"GameItemHero_data">;
  readonly " $fragmentType": "Hero_block";
} | {
  readonly __typename: "ImageContentHero";
  readonly background: {
    readonly image: {
      readonly url: string;
    } | null;
  } | null;
  readonly focusContent: {
    readonly useGameBackground?: boolean | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ImageContentHero_data">;
  readonly " $fragmentType": "Hero_block";
} | {
  readonly __typename: "OddsOrBettingHero";
  readonly " $fragmentSpreads": FragmentRefs<"OddsOrBettingHero_data">;
  readonly " $fragmentType": "Hero_block";
} | {
  readonly __typename: "VideoContentHero";
  readonly background: {
    readonly image: {
      readonly url: string;
    } | null;
  } | null;
  readonly focusContent: {
    readonly useGameBackground?: boolean | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"VideoContentHero_data">;
  readonly " $fragmentType": "Hero_block";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "Hero_block";
};
export type Hero_block$key = {
  readonly " $data"?: Hero_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"Hero_block">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "focusContent",
  "plural": false,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useGameBackground",
          "storageKey": null
        }
      ],
      "type": "FocusContentHeroGame",
      "abstractKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "HeroBackground",
  "kind": "LinkedField",
  "name": "background",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Hero_block",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BasicHero_data"
        }
      ],
      "type": "BasicHero",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DiscoverWeeklyHero_data"
        }
      ],
      "type": "DiscoverWeeklyHero",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ImageContentHero_data"
        },
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "type": "ImageContentHero",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GameItemHero_data"
        }
      ],
      "type": "GameItemHero",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "VideoContentHero_data"
        },
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "type": "VideoContentHero",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OddsOrBettingHero_data"
        }
      ],
      "type": "OddsOrBettingHero",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BettingHero_data"
        }
      ],
      "type": "BettingHero",
      "abstractKey": null
    }
  ],
  "type": "Hero",
  "abstractKey": "__isHero"
};
})();

(node as any).hash = "bed3e5641be4df550d8005aa136997bf";

export default node;
