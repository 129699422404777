import type { FC } from 'react';
import { useTranslation } from '@pafcloud/i18n';
import { DropContainer, ImageContainer, Image, TextContainer } from './shared';
import { DropAmount } from './DropAmount';
import { WinBefore } from './WinBefore';

type Props = {
  thumbnail: string;
  amount: number;
  time?: string | null;
};

export const DailyDrop: FC<Props> = ({ thumbnail, amount, time }) => {
  const { t } = useTranslation('jackpot');

  return (
    <DropContainer type="daily">
      <ImageContainer width={160}>
        <Image src={thumbnail} alt={t(`must-drop.daily.alt-text`)} />
      </ImageContainer>

      <TextContainer>
        <DropAmount amount={amount} />
      </TextContainer>

      <WinBefore time={time} />
    </DropContainer>
  );
};
