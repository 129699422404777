import dynamic from 'next/dynamic';
import type { FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { logger } from '@pafcloud/logging';
import { Stack } from '@pafcloud/base-components';
import type { HeroContentBlock_contentBlock$key } from './__generated__/HeroContentBlock_contentBlock.graphql';
import type { HeroContentBlock_contentBlocks$key } from './__generated__/HeroContentBlock_contentBlocks.graphql';

const HeroButton = dynamic(() => import('./hero-button/HeroButton'));
const HeroBrandLogo = dynamic(() => import('./HeroBrandLogo'));
const HeroSpacing = dynamic(() => import('./HeroSpacing'));
const HeroDepositForm = dynamic(() => import('./HeroDepositForm'));
const HeroLink = dynamic(() => import('./HeroLink'));
const HeroLogo = dynamic(() => import('./HeroLogo'));
const HeroMultipleOffers = dynamic(() => import('./HeroMultipleOffers'));
const HeroRichText = dynamic(() => import('./HeroRichText'));
const HeroSingleLineText = dynamic(() => import('./HeroSingleLineText'));
const HeroCountdown = dynamic(() => import('./HeroCountdown'));
const ProgramGroupBlock = dynamic(() => import('../../bonus').then((module) => module.ProgramGroupBlock));

const contentBlockFragment = graphql`
  fragment HeroContentBlock_contentBlock on HeroContentBlock {
    __typename
    ... on Link {
      ...HeroLink_contentBlock
    }
    ... on Button {
      ...HeroButton_contentBlock
    }
    ... on ProgramGroupBlock {
      ...ProgramGroupBlock_block
    }
    ... on HeroDepositFormBlock {
      ...HeroDepositForm_contentBlock
    }
    ... on HeroLogoBlock {
      ...HeroLogo_contentBlock
    }
    ... on HeroSpacingBlock {
      ...HeroSpacing_contentBlock
    }
    ... on HeroBrandLogoBlock {
      ...HeroBrandLogo_contentBlock
    }
    ... on HeroMultipleOffersBlock {
      ...HeroMultipleOffers_contentBlock
    }
    ... on HeroRichTextBlock {
      ...HeroRichText_contentBlock
    }
    ... on HeroSingleLineTextBlock {
      ...HeroSingleLineText_contentBlock
    }
    ... on HeroCountdownBlock {
      ...HeroCountdown_contentBlock
    }
  }
`;

export const HeroContentBlocksWrapper = styled(Stack)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  maxWidth: 'calc(100vw - 2 * var(--page-margin))',
});

type HeroContentBlockProps = {
  contentBlock: HeroContentBlock_contentBlock$key;
  loading?: 'lazy' | 'eager';
  onHeroClick: () => void;
};

const HeroContentBlock: FC<HeroContentBlockProps> = ({ loading, onHeroClick, ...props }) => {
  const contentBlock = useFragment(contentBlockFragment, props.contentBlock);
  switch (contentBlock.__typename) {
    case 'Link':
      return <HeroLink contentBlock={contentBlock} onHeroClick={onHeroClick} />;
    case 'Button':
      return <HeroButton contentBlock={contentBlock} onHeroClick={onHeroClick} />;
    case 'ProgramGroupBlock':
      return <ProgramGroupBlock block={contentBlock} usedInHero />;
    case 'HeroDepositFormBlock':
      return <HeroDepositForm contentBlock={contentBlock} onHeroClick={onHeroClick} />;
    case 'HeroLogoBlock':
      return <HeroLogo contentBlock={contentBlock} loading={loading} onHeroClick={onHeroClick} />;
    case 'HeroSpacingBlock':
      return <HeroSpacing contentBlock={contentBlock} />;
    case 'HeroBrandLogoBlock':
      return <HeroBrandLogo contentBlock={contentBlock} />;
    case 'HeroMultipleOffersBlock':
      return <HeroMultipleOffers contentBlock={contentBlock} onHeroClick={onHeroClick} />;
    case 'HeroRichTextBlock':
      return <HeroRichText contentBlock={contentBlock} />;
    case 'HeroSingleLineTextBlock':
      return <HeroSingleLineText contentBlock={contentBlock} />;
    case 'HeroCountdownBlock':
      return <HeroCountdown contentBlock={contentBlock} />;
    default:
      logger.error('Attempted to get hero content block from __typename, but __typename was undefined.');
      return null;
  }
};

const contentBlocksFragment = graphql`
  fragment HeroContentBlock_contentBlocks on HeroContentBlock @relay(plural: true) {
    ...HeroContentBlock_contentBlock
  }
`;

type HeroContentBlocksProps = {
  contentBlocks: HeroContentBlock_contentBlocks$key;
  loading?: 'lazy' | 'eager';
  onHeroClick: () => void;
};

export const HeroContentBlocks: FC<HeroContentBlocksProps> = ({ loading, onHeroClick, ...props }) => {
  const contentBlocks = useFragment(contentBlocksFragment, props.contentBlocks);

  if (contentBlocks.length === 0) {
    return null;
  }

  return (
    <HeroContentBlocksWrapper as="section" space={2}>
      {contentBlocks.map((contentBlock, index) => (
        <HeroContentBlock contentBlock={contentBlock} key={index} loading={loading} onHeroClick={onHeroClick} />
      ))}
    </HeroContentBlocksWrapper>
  );
};
