import { useEffect, useState } from 'react';
import { logger } from '@pafcloud/logging';
import type { Offering as ko } from '@pafcloud/kambi-offering';
import { formatOutcomes, fetchOffering } from '@pafcloud/kambi-offering';
import type { BettingEvent } from './BettingCard';

export async function fetchKambiEvent(url: string) {
  const betOfferResponse = await fetchOffering<ko.BetOfferResponse>(url);

  const eventsWithOutcomes = normalizeBetOfferResponse(betOfferResponse);

  if (eventsWithOutcomes.length) {
    return eventsWithOutcomes[0];
  }

  return null;
}

export const useFetchKambiEvent = (eventUrl: string | null, promotedEventUrl?: string | null) => {
  const [bettingEvent, setBettingEvent] = useState<BettingEvent | null>(null);

  const url = promotedEventUrl ?? eventUrl;

  useEffect(() => {
    if (url) {
      void fetchKambiEvent(url)
        .then((event) => {
          if (event) {
            setBettingEvent(event);
          }
        })
        .catch((error) => {
          logger.info(`Failed to fetch from Kambi`, { url, error });
        });
    }
  }, [url]);

  return bettingEvent;
};

export function normalizeBetOfferResponse(betOfferResponse: ko.BetOfferResponse): BettingEvent[] {
  return betOfferResponse.betOffers.reduce<BettingEvent[]>((acc, bo) => {
    const event = betOfferResponse.events.find((e) => bo.eventId === e.id);

    if (event) {
      acc.push({
        id: event.id,
        label: event.path[event.path.length - 1].name,
        homeName: event.homeName,
        awayName: event.awayName,
        start: event.start,
        sport: event.sport,
        paths: event.path.map((path) => path.name),
        outcomes: formatOutcomes(bo.outcomes),
        trackingItem: event.path.map((path) => path.englishName).join(' | '),
      });
    }

    return acc;
  }, []);
}

const MAX_SPORTS_TO_SHOW = 14;

export type BettingSport = {
  id: number;
  name: string;
  termKey: string;
  path: string;
};

export const useFetchKambiSports = (sportsUrl: string | null) => {
  const [bettingSports, setBettingSports] = useState<BettingSport[]>([]);

  useEffect(() => {
    if (sportsUrl) {
      void fetchOffering<ko.GroupResponse>(sportsUrl)
        .then(async (groupResponse) => {
          const groups = groupResponse.group.groups?.slice(0, MAX_SPORTS_TO_SHOW) ?? [];
          if (!groups.length) {
            return;
          }

          const sports = await Promise.all(
            groups.map(async (group) => {
              return {
                id: group.id,
                name: group.name, // Localized name
                termKey: group.termKey,
                path: `#filter/${group.termKey}`,
              };
            }),
          );

          setBettingSports(sports);
        })
        .catch((error) => {
          logger.info(`Failed to fetch from Kambi`, { url: sportsUrl, error });
        });
    }
  }, [sportsUrl]);

  return bettingSports;
};
