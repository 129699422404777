import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { Colors as Colors_default } from './colors.default';
import { Colors as Colors_clean } from './colors.clean';

export const HeroColors = (() => {
  if ($buildEnv.theme === 'clean') {
    return Colors_clean;
  }

  return Colors_default;
})();
