/**
 * @generated SignedSource<<c21d690bb93300721f5dc231c6cca6a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TagFilterBlock_block$data = {
  readonly blocks: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"BlockRenderer_blocks">;
  } | null>;
  readonly gameListId: string;
  readonly showAllButtonText: string;
  readonly tags: ReadonlyArray<{
    readonly __typename: "Tag";
    readonly slug: string;
    readonly title: string;
  } | {
    readonly __typename: "TagGroup";
    readonly tags: ReadonlyArray<{
      readonly slug: string;
      readonly title: string;
    }>;
    readonly title: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"FilterGridGameList_trackable">;
  readonly " $fragmentType": "TagFilterBlock_block";
};
export type TagFilterBlock_block$key = {
  readonly " $data"?: TagFilterBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"TagFilterBlock_block">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "slug",
    "storageKey": null
  },
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TagFilterBlock_block",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gameListId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "Tag",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Tag",
              "kind": "LinkedField",
              "name": "tags",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "type": "TagGroup",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showAllButtonText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "blocks",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BlockRenderer_blocks"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FilterGridGameList_trackable"
    }
  ],
  "type": "TagFilterBlock",
  "abstractKey": null
};
})();

(node as any).hash = "7e50408c3081006add7c8a6d9afad7be";

export default node;
