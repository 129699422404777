/**
 * @generated SignedSource<<c8c3c76a0e4a8133ac3aca7ea9f49613>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PokerGooglePlayLink_data$data = {
  readonly androidApp: {
    readonly description: string;
    readonly url: string;
  } | null;
  readonly " $fragmentType": "PokerGooglePlayLink_data";
};
export type PokerGooglePlayLink_data$key = {
  readonly " $data"?: PokerGooglePlayLink_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"PokerGooglePlayLink_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PokerGooglePlayLink_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AppDownload",
      "kind": "LinkedField",
      "name": "androidApp",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PokerLauncher",
  "abstractKey": null
};

(node as any).hash = "8abb107a113ac1cc1680834e2afb74d2";

export default node;
