/**
 * @generated SignedSource<<f7a42e57b96afc4c163b9e2b76650cb9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoyaltyProgramPayout_payout$data = {
  readonly estimatedCashback: number | null;
  readonly rewardPeriodEndsAt: string;
  readonly " $fragmentType": "LoyaltyProgramPayout_payout";
};
export type LoyaltyProgramPayout_payout$key = {
  readonly " $data"?: LoyaltyProgramPayout_payout$data;
  readonly " $fragmentSpreads": FragmentRefs<"LoyaltyProgramPayout_payout">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LoyaltyProgramPayout_payout",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "estimatedCashback",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rewardPeriodEndsAt",
      "storageKey": null
    }
  ],
  "type": "LoyaltyProgramPlayer",
  "abstractKey": null
};

(node as any).hash = "05208e78365279576971d980226fbf40";

export default node;
