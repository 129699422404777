/**
 * @generated SignedSource<<4288f93b2537af2e0d605b38043208a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useEventParticipantJerseysQuery$variables = {
  participantIds: ReadonlyArray<string>;
};
export type useEventParticipantJerseysQuery$data = {
  readonly sportParticipants: ReadonlyArray<{
    readonly jersey: {
      readonly altText: string | null;
      readonly height: number;
      readonly url: string;
      readonly width: number;
    } | null;
    readonly participantId: string;
  }>;
};
export type useEventParticipantJerseysQuery = {
  response: useEventParticipantJerseysQuery$data;
  variables: useEventParticipantJerseysQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "participantIds"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "participantIds",
        "variableName": "participantIds"
      }
    ],
    "concreteType": "SportParticipant",
    "kind": "LinkedField",
    "name": "sportParticipants",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "participantId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Image",
        "kind": "LinkedField",
        "name": "jersey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "height",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "width",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "altText",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useEventParticipantJerseysQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useEventParticipantJerseysQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b21f45301848ec02af38700998a8345b",
    "id": null,
    "metadata": {},
    "name": "useEventParticipantJerseysQuery",
    "operationKind": "query",
    "text": "query useEventParticipantJerseysQuery(\n  $participantIds: [ID!]!\n) {\n  sportParticipants(participantIds: $participantIds) {\n    participantId\n    jersey {\n      url\n      height\n      width\n      altText\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "93ec8c1fa59848050b25fc3c1db7544a";

export default node;
