import type { FC } from 'react';
import { useTranslation } from '@pafcloud/i18n';
import { DropContainer, ImageContainer, Image, TextContainer } from './shared';
import { DropAmount } from './DropAmount';

type Props = {
  thumbnail: string;
  amount: number;
};

export const SuperDrop: FC<Props> = ({ thumbnail, amount }) => {
  const { t } = useTranslation('jackpot');

  return (
    <DropContainer type="super">
      <ImageContainer width={120}>
        <Image src={thumbnail} alt={t(`must-drop.progressive.alt-text`)} />
      </ImageContainer>

      <TextContainer>
        <DropAmount amount={amount} />
      </TextContainer>
    </DropContainer>
  );
};
