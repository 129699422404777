/**
 * @generated SignedSource<<7894fbf1bc8c43cc4ad738cab81624d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useFreezeLevelMutation$variables = Record<PropertyKey, never>;
export type useFreezeLevelMutation$data = {
  readonly freezeLevel: {
    readonly player: {
      readonly loyaltyProgram: {
        readonly currentLevelFrozen: boolean;
        readonly remainingFreezes: number;
      } | null;
    } | null;
  };
};
export type useFreezeLevelMutation = {
  response: useFreezeLevelMutation$data;
  variables: useFreezeLevelMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "LoyaltyProgramPlayer",
  "kind": "LinkedField",
  "name": "loyaltyProgram",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentLevelFrozen",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remainingFreezes",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useFreezeLevelMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FreezeLevelPayload",
        "kind": "LinkedField",
        "name": "freezeLevel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Player",
            "kind": "LinkedField",
            "name": "player",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useFreezeLevelMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FreezeLevelPayload",
        "kind": "LinkedField",
        "name": "freezeLevel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Player",
            "kind": "LinkedField",
            "name": "player",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f8405798de354e36e93b0a03031e93be",
    "id": null,
    "metadata": {},
    "name": "useFreezeLevelMutation",
    "operationKind": "mutation",
    "text": "mutation useFreezeLevelMutation {\n  freezeLevel {\n    player {\n      loyaltyProgram {\n        currentLevelFrozen\n        remainingFreezes\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4f9a760e64bbb3c1eacdb1594748f05a";

export default node;
