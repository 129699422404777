import type { FC } from 'react';
import styled from '@emotion/styled';
import { useFragment, graphql } from 'react-relay/hooks';
import { GameCard, CardInfoSausage, CardBody, Heading, Section } from '@pafcloud/base-components';
import { FontHeadingSize, FontTextSize, Font } from '@pafcloud/style';
import { GameListTitle } from '@pafcloud/game-components';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import type { PokerLauncherItem_data$key } from './__generated__/PokerLauncherItem_data.graphql';
import { PokerLaunchButton } from './PokerLaunchButton';
import { PokerDownloadButton } from './PokerDownloadButton';
import { PokerAppStoreLink } from './PokerAppStoreLink';
import { PokerGooglePlayLink } from './PokerGooglePlayLink';

const dataFragment = graphql`
  fragment PokerLauncherItem_data on PokerLauncher {
    heading
    title
    tagline
    info
    backgroundUrl
    playInBrowserButton
    enablePopUpReminder
    ...PokerDownloadButton_data
    ...PokerAppStoreLink_data
    ...PokerGooglePlayLink_data
  }
`;

export const PokerSection = styled.section({
  '--page-margin': $buildEnv.theme === 'classic-red' ? undefined : 'var(--full-width-margin)',

  paddingRight: 'var(--page-margin)',
  paddingLeft: 'var(--page-margin)',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const PokerCard = styled(GameCard)({
  overflow: 'hidden',
});

const Info = styled.p({
  position: 'relative',
  margin: 0,
  top: -4,
  textAlign: 'center',
  fontSize: FontTextSize.Tiny,
});

const ReminderInfo = styled(Info)({
  marginTop: 8,
});

const CardTitle = styled.div({
  margin: '24px 0 16px',
});

const PlayNowHeading = styled(Heading)({
  margin: 0,
  fontWeight: 400,
  fontFamily: Font.HeadingAlt,
  fontSize: FontHeadingSize.Small,
  textAlign: 'center',
});

const Download = styled.div({
  display: 'flex',
  justifyItems: 'center',
  paddingTop: 16,
  '* + * ': {
    marginLeft: 8,
  },
  svg: {
    minHeight: 40,
    maxWidth: '100%',
  },
});

type PokerItemProps = {
  data: PokerLauncherItem_data$key;
};

export const PokerLauncherItem: FC<PokerItemProps> = (props) => {
  const data = useFragment(dataFragment, props.data);

  return (
    <PokerSection className="full-width">
      {data.heading && <GameListTitle title={data.heading} />}
      <PokerCard backgroundUrl={data.backgroundUrl}>
        <CardBody>
          {data.tagline && <CardInfoSausage text={data.tagline} />}
          <CardTitle>
            <Section>
              <PlayNowHeading>{data.title}</PlayNowHeading>
              {data.info && <Info>{data.info}</Info>}
            </Section>
          </CardTitle>
          <PokerLaunchButton>{data.playInBrowserButton}</PokerLaunchButton>
          <ReminderInfo>{data.enablePopUpReminder}</ReminderInfo>
        </CardBody>
      </PokerCard>
      <Download>
        <PokerDownloadButton data={data} />
        <PokerAppStoreLink data={data} />
        <PokerGooglePlayLink data={data} />
      </Download>
    </PokerSection>
  );
};
