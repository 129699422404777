import dynamic from 'next/dynamic';
import { TableBlock } from '@pafcloud/block-components';
import { RichTextBlock } from '@pafcloud/rich-text';

import BettingBlock from './betting-block/BettingBlock';
import BettingClientBlock from './betting-client-block/BettingClientBlock';
import BettingListBlock from './betting-list/BettingListBlock';
import BonusTermsBlock from './bonus-terms-block/BonusTermsBlock';
import DepositFormBlock from './deposit-form-block/DepositFormBlock';
import GameShortcutBlock from './game-shortcut/GameShortcutBlock';
import HeroBlock from './hero-block/HeroBlock';
import IframeBlock from './iframe-block/IframeBlock';
import InformationContainerBlock from './information-container-block/InformationContainerBlock';
import LinkContainerBlock from './link-container-block/LinkContainerBlock';
import MultiplayerBingoListBlock from './game-lists/multiplayer-bingo-list-block/MultiplayerBingoListBlock';
import MustDropBlock from './must-drop-block/MustDropBlock';
import PokerLauncherBlock from './poker-launcher-block/PokerLauncherBlock';
import PromotionsBlock from './promotion-block/PromotionsBlock';
import SearchBlock from './search-block/SearchBlock';
import GameplayGameListBlock from './game-lists/gameplay-game-list-block/GameplayGameListBlock';
import PosterGameListBlock from './game-lists/poster-game-list-block/PosterGameListBlock';
import BillboardGameListBlock from './game-lists/billboard-game-list-block/BillboardGameListBlock';
import NumberedGameListBlock from './game-lists/numbered-game-list-block/NumberedGameListBlock';
import MosaicGameListBlock from './game-lists/mosaic-game-list-block/MosaicGameListBlock';
import GridGameListBlock from './game-lists/grid-game-list-block/GridGameListBlock';
import TabGroupLinksBlock from './tab-group-block/TabGroupLinksBlock';
import HighlightGameListBlock from './game-lists/highlight-game-list-block/HighlightGameListBlock';
import LoyaltyProgramDetailsBlock from './loyalty-program/LoyaltyProgramDetailsBlock';
import LoyaltyProgramProgressBlock from './loyalty-program/LoyaltyProgramProgressBlock';

// This block will only ever be shown to logged in players
const ProgramGroupBlock = dynamic(() =>
  import('@pafcloud/block-components').then((module) => module.ProgramGroupBlock),
);

// NOTE: If adding a new block: it should be added to the
// fragment in PageBlockRenderer (PageBlockRenderer_blocks) aswell.
// If your block contains other blocks it should not be added here.

export const blockComponents = {
  BettingBlock,
  BettingClientBlock,
  BettingListBlock,
  BonusTermsBlock,
  DepositFormBlock,
  GameShortcutBlock,
  HeroBlock,
  IframeBlock,
  InformationContainerBlock,
  LinkContainerBlock,
  ProgramGroupBlock,
  MultiplayerBingoListBlock,
  MustDropBlock,
  PokerLauncherBlock,
  PromotionsBlock,
  RichTextBlock,
  SearchBlock,
  TabGroupLinksBlock,
  TableBlock,
  GameplayGameListBlock,
  PosterGameListBlock,
  BillboardGameListBlock,
  NumberedGameListBlock,
  MosaicGameListBlock,
  GridGameListBlock,
  HighlightGameListBlock,
  LoyaltyProgramDetailsBlock,
  LoyaltyProgramProgressBlock,
};
