/**
 * @generated SignedSource<<5c87d5e124f7f97185e78fc280fed50e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoyaltyProgramProgressBlock_block$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LoyaltyProgramProgress_block">;
  readonly " $fragmentType": "LoyaltyProgramProgressBlock_block";
};
export type LoyaltyProgramProgressBlock_block$key = {
  readonly " $data"?: LoyaltyProgramProgressBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"LoyaltyProgramProgressBlock_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LoyaltyProgramProgressBlock_block",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LoyaltyProgramProgress_block"
    }
  ],
  "type": "LoyaltyProgramProgressBlock",
  "abstractKey": null
};

(node as any).hash = "ab187c6ccc7b0ae43a3c90c9797d6ee3";

export default node;
