import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { DepositToPlayForm } from '@pafcloud/block-components';
import type { DepositFormBlock_block$key } from './__generated__/DepositFormBlock_block.graphql';

const blockFragment = graphql`
  fragment DepositFormBlock_block on DepositFormBlock {
    depositButtonText
  }
`;

type DepositFormBlockProps = {
  block: DepositFormBlock_block$key;
};

const DepositFormBlock: FC<DepositFormBlockProps> = (props) => {
  const block = useFragment(blockFragment, props.block);
  return <DepositToPlayForm submitButtonText={block.depositButtonText} />;
};

export default DepositFormBlock;
