import styled from '@emotion/styled';
import { Breakpoint, FontHeadingSize } from '@pafcloud/style';
import { MustDropColors } from './must-drop-colors';

export const AmountText = styled.span({
  color: MustDropColors.TextHighlight,
  fontSize: FontHeadingSize.Small,
  fontWeight: 'bold',
  marginBottom: 6,
});

type Drop = 'minute' | 'daily' | 'super';

export const DropContainer = styled.div<{ type: Drop }>(({ type }) => ({
  gridArea: type,
  textAlign: 'center',
  borderRadius: 6,
  padding: 8,
  border: `solid 1px ${MustDropColors.Border}`,
}));

export const ImageContainer = styled.div<{ width: number }>(({ width }) => ({
  width,
  margin: '0 auto',
  marginTop: 10,
  marginBottom: 10,
  [Breakpoint.TabletOrLarger]: {
    marginTop: 25,
  },
}));

export const Image = styled.img({
  width: '100%',
});

export const TextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1.1rem',
  marginBottom: 6,
  fontWeight: 'bold',
});
