/**
 * @generated SignedSource<<42b5cc118a003862417fd1563d9b6b6d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IframeBlock_block$data = {
  readonly url: string;
  readonly " $fragmentType": "IframeBlock_block";
};
export type IframeBlock_block$key = {
  readonly " $data"?: IframeBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"IframeBlock_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IframeBlock_block",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "type": "IframeBlock",
  "abstractKey": null
};

(node as any).hash = "fb5876ce4c3d14edb5be449646291537";

export default node;
