import { useEffect } from 'react';
import { useHandler, usePoller } from '@pafcloud/react-hook-utils';

type UseBingoProps = {
  refetch: () => void;
};

export const useBingo = ({ refetch }: UseBingoProps) => {
  const { startPolling, stopPolling } = usePoller(3000);

  const fetchAndSave = useHandler(refetch);

  useEffect(() => {
    startPolling(async () => {
      void fetchAndSave();
      return true;
    });
    return () => stopPolling();
  }, [startPolling, stopPolling, fetchAndSave]);
};
