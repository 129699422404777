import { useEffect, useRef, useState } from 'react';

export const createAnimationWithFps = (fps: number) => {
  const fpsDelay = 1000 / fps;
  let running = true;

  const runAnimation = (callback: (nextFrame: () => void) => void) => {
    let start = Date.now();

    const nextFrame = () => {
      if (!running) {
        return;
      }

      const now = Date.now();
      const elapsed = now - start;

      if (elapsed < fpsDelay) {
        window.requestAnimationFrame(nextFrame);
        return;
      }

      start = now;
      callback(() => window.requestAnimationFrame(nextFrame));
    };

    nextFrame();
  };

  const stopAnimation = () => {
    running = false;
  };

  return { runAnimation, stopAnimation };
};

export const useTween = (value: number, initialTimeout = 5_000) => {
  const [currentValue, setCurrentValue] = useState(value);
  const startRefValue = useRef(value);
  const currentRefValue = useRef(value);
  const timeoutRefValue = useRef(initialTimeout);

  useEffect(() => {
    const { runAnimation, stopAnimation } = createAnimationWithFps(10);

    const startTime = Date.now();
    const endTime = startTime + timeoutRefValue.current + 1000;

    runAnimation((nextFrame) => {
      const now = Date.now();
      const interval = endTime - startTime;
      const elapsedTime = now - startTime;
      const diff = value - startRefValue.current;

      const nowValue = startRefValue.current + (diff / interval) * elapsedTime;
      currentRefValue.current = nowValue;
      setCurrentValue(nowValue);

      if (nowValue !== value) {
        nextFrame();
      }
    });

    return () => {
      timeoutRefValue.current = Date.now() - startTime;
      startRefValue.current = currentRefValue.current * 1;

      stopAnimation();
    };
  }, [value]);

  return currentValue;
};
