/**
 * @generated SignedSource<<ce90313efffdfc9fade627f694f0ef15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PromotionsGrid_promotions$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"PromotionCard_promotion">;
  readonly " $fragmentType": "PromotionsGrid_promotions";
}>;
export type PromotionsGrid_promotions$key = ReadonlyArray<{
  readonly " $data"?: PromotionsGrid_promotions$data;
  readonly " $fragmentSpreads": FragmentRefs<"PromotionsGrid_promotions">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "PromotionsGrid_promotions",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PromotionCard_promotion"
    }
  ],
  "type": "PromotionCard",
  "abstractKey": null
};

(node as any).hash = "3831ba17dfd31740e2905ec6cd734668";

export default node;
