import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { removeNullValues } from '@pafcloud/collection-utils';
import type { PageBlockRenderer_blocks$key } from './__generated__/PageBlockRenderer_blocks.graphql';
import { getBlockComponent } from './BlockRenderer';
import { blockComponents } from './blockComponents';
import AccordionBlock from './accordion-block/AccordionBlock';
import ContainerBlock from './container-block/ContainerBlock';
import BackgroundContainerBlock from './background-container-block/BackgroundContainerBlock';
import TagFilterBlock from './tag-filter/TagFilterBlock';

// NOTE: If adding a new block: it should also be added to the
// fragment in BlockRenderer (BlockRenderer_blocks).
// But if your block contains other blocks it should only be added in this file.

const pageBlockComponents = {
  ...blockComponents,
  AccordionBlock,
  BackgroundContainerBlock,
  ContainerBlock,
  TagFilterBlock,
};

const blocksFragment = graphql`
  fragment PageBlockRenderer_blocks on Block @relay(plural: true) {
    __typename
    ...AccordionBlock_block
    ...BackgroundContainerBlock_block
    ...BettingBlock_block
    ...BettingClientBlock_block
    ...BettingListBlock_block
    ...BonusTermsBlock_block
    ...ContainerBlock_block
    ...DepositFormBlock_block
    ...GameShortcutBlock_block
    ...HeroBlock_block
    ...IframeBlock_block
    ...InformationContainerBlock_block
    ...LinkContainerBlock_block
    ...MultiplayerBingoListBlock_block
    ...MustDropBlock_block
    ...PokerLauncherBlock_block
    ...ProgramGroupBlock_block
    ...PromotionsBlock_block
    ...RichTextBlock_block
    ...SearchBlock_block
    ...TabGroupLinksBlock_block
    ...TableBlock_block
    ...TagFilterBlock_block
    ...GameplayGameListBlock_block
    ...PosterGameListBlock_block
    ...BillboardGameListBlock_block
    ...NumberedGameListBlock_block
    ...MosaicGameListBlock_block
    ...GridGameListBlock_block
    ...HighlightGameListBlock_block
    ...LoyaltyProgramDetailsBlock_block
    ...LoyaltyProgramProgressBlock_block
  }
`;

type Props = {
  blocks: PageBlockRenderer_blocks$key;
};

export const PageBlockRenderer: FC<Props> = (props) => {
  const blocks = useFragment(blocksFragment, props.blocks);

  return (
    <>
      {blocks.map((block, index) => {
        const Component = getBlockComponent(block.__typename, pageBlockComponents);

        if (Component.name === 'ContainerBlockRenderer') {
          return (
            <Component
              key={index}
              block={block}
              blockRenderer={<PageBlockRenderer blocks={removeNullValues(props.blocks)} />}
            />
          );
        }

        if (Component) {
          return <Component key={index} block={block} firstInMain={index === 0} />;
        }

        return null;
      })}
    </>
  );
};
