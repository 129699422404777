/**
 * @generated SignedSource<<e2adfbbe1225361b1a841d213e9e883f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MustDropBlock_block$data = {
  readonly mustDropGameList: {
    readonly " $fragmentSpreads": FragmentRefs<"GameList_gameList">;
  } | null;
  readonly numberOfRows: number;
  readonly thumbnails: {
    readonly dailyDrop: string;
    readonly minuteDrop: string;
    readonly superDrop: string;
  };
  readonly title: string | null;
  readonly " $fragmentType": "MustDropBlock_block";
};
export type MustDropBlock_block$key = {
  readonly " $data"?: MustDropBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"MustDropBlock_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MustDropBlock_block",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfRows",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GameList",
      "kind": "LinkedField",
      "name": "mustDropGameList",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GameList_gameList"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MustDropThumbnails",
      "kind": "LinkedField",
      "name": "thumbnails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minuteDrop",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dailyDrop",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "superDrop",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MustDropBlock",
  "abstractKey": null
};

(node as any).hash = "fab96cec2c70f98ce0c0d2376afb0664";

export default node;
