/**
 * @generated SignedSource<<97ce3db04c14ada2dee862126a179143>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BettingBlock_block$data = {
  readonly eventUrl: string | null;
  readonly link: {
    readonly ariaLabel: string | null;
    readonly text: string;
    readonly url: string;
  } | null;
  readonly promotedEvent: {
    readonly image: string;
    readonly url: string | null;
  } | null;
  readonly promotedEventGroup: {
    readonly image: string | null;
    readonly url: string | null;
  } | null;
  readonly showSportLinks: boolean;
  readonly sportsUrl: string | null;
  readonly title: string | null;
  readonly " $fragmentType": "BettingBlock_block";
};
export type BettingBlock_block$key = {
  readonly " $data"?: BettingBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"BettingBlock_block">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "image",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BettingBlock_block",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Link",
      "kind": "LinkedField",
      "name": "link",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ariaLabel",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showSportLinks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BettingEvent",
      "kind": "LinkedField",
      "name": "promotedEvent",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BettingEventGroup",
      "kind": "LinkedField",
      "name": "promotedEventGroup",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sportsUrl",
      "storageKey": null
    }
  ],
  "type": "BettingBlock",
  "abstractKey": null
};
})();

(node as any).hash = "097a72dbe779c8c127cf032e58aa75ac";

export default node;
