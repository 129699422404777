/**
 * @generated SignedSource<<516a1ebf79cc24edfde7323877d12636>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GridGameListBlock_block$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GridGameList_block">;
  readonly " $fragmentType": "GridGameListBlock_block";
};
export type GridGameListBlock_block$key = {
  readonly " $data"?: GridGameListBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"GridGameListBlock_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GridGameListBlock_block",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GridGameList_block"
    }
  ],
  "type": "GridGameListBlock",
  "abstractKey": null
};

(node as any).hash = "73015701c1931c7a47b2493f8f823076";

export default node;
