/**
 * @generated SignedSource<<13f63d470bcca4127407787033040258>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DepositFormBlock_block$data = {
  readonly depositButtonText: string;
  readonly " $fragmentType": "DepositFormBlock_block";
};
export type DepositFormBlock_block$key = {
  readonly " $data"?: DepositFormBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"DepositFormBlock_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DepositFormBlock_block",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "depositButtonText",
      "storageKey": null
    }
  ],
  "type": "DepositFormBlock",
  "abstractKey": null
};

(node as any).hash = "c558d916e5f3d22a23e486f655f8b137";

export default node;
