import styled from '@emotion/styled';
import { Breakpoint } from '@pafcloud/style';

export const FullWidthTabLayout = styled.div({
  minWidth: 0,

  [Breakpoint.Phone]: {
    gridColumn: '1 / -1',
    '[role="tablist"]': {
      paddingLeft: 'var(--full-width-margin)',
      paddingRight: 'var(--full-width-margin)',
    },
  },
});
