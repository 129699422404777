import dynamic from 'next/dynamic';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import type { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import { logger } from '@pafcloud/logging';
import { Breakpoint, LayoutProps, Color } from '@pafcloud/style';
import { Ribbon, SkeletonContent } from '@pafcloud/base-components';
import { dispatchTrackingEvent } from '@pafcloud/tracking';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { useHandler } from '@pafcloud/react-hook-utils';
import { isType } from '@pafcloud/collection-utils';
import type { Theme } from '@pafcloud/config';
import type { Hero_block$key, Hero_block$data } from './__generated__/Hero_block.graphql';
import type { Hero_common$key } from './__generated__/Hero_common.graphql';
import { HeroBackground } from './hero-background';
import { HeroContentBlocks } from './hero-content-blocks/HeroContentBlock';
import { HeroColors } from './hero-colors';

const BasicHero = dynamic(() => import('./BasicHero'));
const DiscoverWeeklyHero = dynamic(() => import('./DiscoverWeeklyHero'));
const ImageContentHero = dynamic(() => import('./ImageContentHero'));
const GameItemHero = dynamic(() => import('./GameItemHero'));
const OddsOrBettingHero = dynamic(() => import('./OddsOrBettingHero'));
const VideoContentHero = dynamic(() => import('./VideoContentHero'));
const BettingHero = dynamic(() => import('./BettingHero'));

const commonFragment = graphql`
  fragment Hero_common on CommonHeroFields {
    id
    name
    ribbon {
      text
      color
      placement
    }
    background {
      ...HeroBackground_background
    }
    contentBlocks {
      ...HeroContentBlock_contentBlocks
    }
  }
`;

const blockFragment = graphql`
  fragment Hero_block on Hero {
    __typename
    ... on BasicHero {
      ...BasicHero_data
    }
    ... on DiscoverWeeklyHero {
      ...DiscoverWeeklyHero_data
    }
    ... on ImageContentHero {
      ...ImageContentHero_data
      focusContent {
        ... on FocusContentHeroGame {
          useGameBackground
        }
      }
      background {
        # We don't need the mobile version as this is only used when the game background is the background
        image {
          url
        }
      }
    }
    ... on GameItemHero {
      ...GameItemHero_data
    }
    ... on VideoContentHero {
      ...VideoContentHero_data
      focusContent {
        ... on FocusContentHeroGame {
          useGameBackground
        }
      }
      background {
        # We don't need the mobile version as this is only used when the game background is the background
        image {
          url
        }
      }
    }
    ... on OddsOrBettingHero {
      ...OddsOrBettingHero_data
    }
    ... on BettingHero {
      ...BettingHero_data
    }
  }
`;

export type HeroType = Hero_block$data['__typename'];

const THEME_WITH_SOLID_HEADER: Theme[] = ['classic', 'classic-red', 'quirky-purple'];

const hasSolidHeader = () => {
  return THEME_WITH_SOLID_HEADER.includes($buildEnv.theme);
};

const WavyBottomBorder = styled.div({
  position: 'absolute',
  width: '100%',
  height: 14,
  bottom: 0,
  left: 0,
  right: 0,
  maskImage: 'url("/static/images/wavy-bottom-border.svg")',
  maskPosition: 'center top',
  backgroundColor: Color.Background,
  // Prevent Ribbon Component to render above the wavy border.
  zIndex: 2,
});

type LayoutProp = {
  firstInMain?: boolean;
};

const BackgroundContainer = styled.div({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 0,
  margin: 'auto',
  overflow: 'hidden',
});

const Aside = styled.aside<{ firstInMain?: boolean }>(
  {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    minHeight: '25vh',
    color: HeroColors.Text,
  },

  ({ firstInMain }) => {
    if ($buildEnv.theme === 'clean' || $buildEnv.theme === 'clean-blue') {
      return {
        // Extra space for WavyBottomBorder
        paddingBottom: 16,
      };
    }

    if ($buildEnv.theme === 'golden') {
      return {
        borderBottom: firstInMain ? `3px solid ${Color.Primitive.Primary}` : undefined,
      };
    }

    if ($buildEnv.theme === 'dreams' || $buildEnv.theme === 'goldenV2') {
      return {
        minHeight: firstInMain ? `calc(80vh - ${LayoutProps.GamePauseHeight}px - 36px)` : '50vh',

        [Breakpoint.TabletOrLarger]: {
          minHeight: firstInMain ? `calc(80vh - ${LayoutProps.GamePauseHeight}px - 64px)` : '50vh',
        },
        [Breakpoint.BigScreenOrLarger]: {
          minHeight: firstInMain ? `calc(90vh - ${LayoutProps.GamePauseHeight}px - 64px)` : '50vh',
        },
        [Breakpoint.HDScreenOrLarger]: {
          minHeight: firstInMain ? `calc(100vh - ${LayoutProps.GamePauseHeight}px - 178px)` : '50vh',
        },

        [`${BackgroundContainer}`]: {
          opacity: firstInMain ? 1 : 0.4,
        },

        ':first-of-type': {
          '--hero-margin-bottom': 'min(20vh, 56px)',

          marginBottom: 'calc(var(--hero-margin-bottom) * -1)',
          paddingBottom: 'calc(var(--hero-margin-bottom) / 2)',
        },
      };
    }

    if ($buildEnv.theme === 'momentum' || $buildEnv.theme === 'momentum-neon') {
      return {
        boxShadow: Color.Elevation.Level1 + ',' + Color.Elevation.Level3,
        minHeight: 'max(50vh, 500px)',
        marginRight: 'var(--full-width-margin)',
        marginLeft: 'var(--full-width-margin)',
        borderRadius: 8,
      };
    }
  },

  // Apply custom styles if the hero is rendered on the top of the page.
  // Negative margin to tuck the hero in behind the header, and extra padding to move the content below the header.
  ({ firstInMain }) => {
    // Heros will not appear "below" the header on 11.lv
    if ($buildEnv.theme === 'momentum' || $buildEnv.theme === 'momentum-neon') {
      return {
        marginTop: 24,
      };
    }

    if (firstInMain) {
      return {
        marginTop: hasSolidHeader() ? 0 : LayoutProps.HeaderHeight * -1,
        paddingTop: hasSolidHeader() ? 0 : LayoutProps.HeaderHeight,
      };
    }

    return null;
  },
);

const HeroContainer = styled.div<LayoutProp>(({ firstInMain }) => {
  const commonStyles: CSSObject = {
    position: 'relative',
    display: 'grid',
    gap: 32,
    padding: '40px var(--page-margin)',

    ':empty': {
      display: 'none',
    },

    [Breakpoint.LandscapePhone]: {
      padding: '16px var(--page-margin)',
    },
  };

  if ($buildEnv.theme === 'golden' || $buildEnv.theme === 'quirky') {
    return {
      ...commonStyles,
      padding: '64px var(--page-margin)',
      marginTop: firstInMain ? -32 : undefined,
    };
  }

  if ($buildEnv.theme === 'quirky-purple') {
    return {
      ...commonStyles,
      padding: '64px var(--page-margin)',
    };
  }

  if ($buildEnv.theme === 'dreams' || $buildEnv.theme === 'goldenV2') {
    return {
      '--page-margin': 'var(--full-width-margin)',

      ...commonStyles,
      paddingTop: 56,
      paddingBottom: 56,
      flexBasis: '100%',
      alignContent: firstInMain ? 'end' : 'center',
      alignItems: 'center',
    };
  }

  if ($buildEnv.theme === 'momentum' || $buildEnv.theme === 'momentum-neon') {
    return {
      '--page-margin': 'var(--full-width-margin)',

      ...commonStyles,
      paddingTop: 56,
      paddingBottom: 56,
      flexBasis: '100%',
      alignContent: 'center',
      alignItems: 'center',
    };
  }

  return commonStyles;
});

export const SkeletonHero = styled(Aside)({
  animationDuration: '4s',
}).withComponent(SkeletonContent);

const getGameBackground = (block: Hero_block$data) => {
  if (isType(block, 'ImageContentHero', 'VideoContentHero') && block.focusContent?.useGameBackground) {
    return block.background?.image?.url ?? null;
  }

  return null;
};

type HeroComponentProps = {
  block: Hero_block$key;
  common: Hero_common$key;
  firstInMain?: boolean;
};

export const Hero: FC<HeroComponentProps> = ({ firstInMain, ...props }) => {
  const common = useFragment(commonFragment, props.common);
  const block = useFragment(blockFragment, props.block);
  const { ribbon, background, contentBlocks } = common;
  const [isHidden, setIsHidden] = useState(false);

  const trackHero = useHandler((event: 'hero-click' | 'hero-impression') => {
    dispatchTrackingEvent(event, {
      hero: {
        contentName: `Hero: ${block.__typename}`,
        itemName: common.name,
        pagePosition: firstInMain ? 'main-hero' : 'hero',
        type: 'hero',
        index: 0,
      },
    });
  });

  useEffect(() => {
    trackHero('hero-impression');
  }, [trackHero]);

  if (isHidden) {
    return null;
  }

  const gameBackground = getGameBackground(block);

  const loading = firstInMain ? 'eager' : 'lazy';

  const renderHeroType = () => {
    const content = (
      <HeroContentBlocks contentBlocks={contentBlocks} loading={loading} onHeroClick={() => trackHero('hero-click')} />
    );

    switch (block.__typename) {
      case 'BasicHero':
        return <BasicHero data={block} content={content} />;
      case 'DiscoverWeeklyHero':
        return (
          <DiscoverWeeklyHero
            data={block}
            content={content}
            loading={loading}
            onHeroClick={() => trackHero('hero-click')}
          />
        );
      case 'GameItemHero':
        return (
          <GameItemHero data={block} content={content} loading={loading} onHeroClick={() => trackHero('hero-click')} />
        );
      case 'ImageContentHero':
        return (
          <ImageContentHero
            data={block}
            content={content}
            loading={loading}
            onHeroClick={() => trackHero('hero-click')}
          />
        );
      case 'OddsOrBettingHero':
        return (
          <OddsOrBettingHero
            data={block}
            content={content}
            loading={loading}
            onHeroClick={() => trackHero('hero-click')}
          />
        );
      case 'VideoContentHero':
        return (
          <VideoContentHero
            data={block}
            content={content}
            loading={loading}
            onHeroClick={() => trackHero('hero-click')}
          />
        );
      case 'BettingHero':
        return (
          <BettingHero
            data={block}
            content={content}
            onHeroClick={() => trackHero('hero-click')}
            setIsHidden={setIsHidden}
          />
        );
      default:
        logger.error('Attempted to get hero from __typename, but __typename was undefined.');
        return null;
    }
  };

  return (
    <Aside className="full-width" data-name="hero" firstInMain={firstInMain}>
      <BackgroundContainer>
        <HeroBackground
          background={background}
          gameBackground={gameBackground}
          heroType={block.__typename}
          loading={loading}
        />
        {ribbon && <Ribbon text={ribbon.text} color={ribbon.color} placement={ribbon.placement} />}
        {($buildEnv.theme === 'clean' || $buildEnv.theme === 'clean-blue') && firstInMain && <WavyBottomBorder />}
      </BackgroundContainer>

      <HeroContainer firstInMain={firstInMain}>{renderHeroType()}</HeroContainer>
    </Aside>
  );
};
