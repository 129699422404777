/**
 * @generated SignedSource<<828f5af29225acf4a87a802b4b8b1edd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BackgroundContainerBlock_block$data = {
  readonly backgroundContainerImage: string;
  readonly blocks: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"BlockRenderer_blocks">;
  } | null>;
  readonly colors: string;
  readonly disableOpacity: boolean;
  readonly hasGradient: boolean;
  readonly position: string;
  readonly " $fragmentType": "BackgroundContainerBlock_block";
};
export type BackgroundContainerBlock_block$key = {
  readonly " $data"?: BackgroundContainerBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"BackgroundContainerBlock_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BackgroundContainerBlock_block",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "blocks",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BlockRenderer_blocks"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backgroundContainerImage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "disableOpacity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasGradient",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "colors",
      "storageKey": null
    }
  ],
  "type": "BackgroundContainerBlock",
  "abstractKey": null
};

(node as any).hash = "192c6da607f3ea11fd64f86e777bcbf3";

export default node;
