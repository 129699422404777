import type { FC } from 'react';
import { differenceInMinutes } from 'date-fns/differenceInMinutes';
import styled from '@emotion/styled';
import { useTranslation } from '@pafcloud/i18n';
import { useFormatAmount } from '@pafcloud/react-hook-utils';
import { DropContainer, AmountText, ImageContainer, Image, TextContainer } from './shared';
import { DropAmount } from './DropAmount';
import { WinBefore } from './WinBefore';
import { parseInputDate } from './parseInputDate';

const NextJackpot = styled.span({
  marginLeft: 6,
});

const showHasBeenWonSection = (minutes: number): boolean => {
  if (minutes >= 1) {
    return false;
  }
  return true;
};

type Props = {
  thumbnail: string;
  amount: number;
  prevWinAmount?: number;
  time?: string | null;
};

export const MinuteDrop: FC<Props> = ({ thumbnail, amount, prevWinAmount, time }) => {
  const { t } = useTranslation('jackpot');
  const formatAmount = useFormatAmount();

  const timeLeftInMinutes = time && differenceInMinutes(parseInputDate(time), new Date());

  return (
    <DropContainer type="minute">
      <ImageContainer width={120}>
        <Image src={thumbnail} alt={t(`must-drop.minute.alt-text`)} />
      </ImageContainer>

      {timeLeftInMinutes && showHasBeenWonSection(timeLeftInMinutes) ? (
        <>
          <TextContainer>
            <AmountText>{formatAmount(prevWinAmount)}</AmountText>
            {t('must-drop.minute.has-been-won')}
          </TextContainer>
          <TextContainer>
            {t('must-drop.minute.next-jackpot')}
            <NextJackpot>{<DropAmount amount={amount} />}</NextJackpot>
          </TextContainer>
        </>
      ) : (
        <>
          <TextContainer>
            <DropAmount amount={amount} />
          </TextContainer>
          <WinBefore time={time} />
        </>
      )}
    </DropContainer>
  );
};
