import type { FC } from 'react';
import styled from '@emotion/styled';
import { Color, Font, FontHeadingSize } from '@pafcloud/style';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  margin: '24px 0 16px',
  fontFamily: Font.HeadingAlt,
  fontSize: FontHeadingSize.Small,
  fontWeight: $buildEnv.theme === 'momentum' ? 900 : undefined,
  color: Color.HeadingText,
});

const TeamName = styled.span({
  textAlign: 'center',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  display: '-webkit-box',
});

export const BettingDivider = styled.span({
  flexShrink: 0,
  paddingLeft: '0.5em',
  paddingRight: '0.5em',
});

type Props = {
  homeName: string;
  awayName: string;
  className?: string;
};

export const BettingTitle: FC<Props> = ({ homeName, awayName, className }) => {
  return (
    <Wrapper className={className}>
      <TeamName>{homeName}</TeamName>
      <BettingDivider>{'-'}</BettingDivider>
      <TeamName>{awayName}</TeamName>
    </Wrapper>
  );
};
