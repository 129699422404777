/**
 * @generated SignedSource<<bf76d4e32036ef9922b00e274326b639>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BettingListBlock_block$data = {
  readonly bettingListVariant: {
    readonly groupIds: ReadonlyArray<string>;
    readonly id: string;
    readonly variant: string | null;
  };
  readonly kambiOffering: {
    readonly baseUrl: string;
    readonly lang: string;
    readonly market: string;
  };
  readonly title: string | null;
  readonly " $fragmentType": "BettingListBlock_block";
};
export type BettingListBlock_block$key = {
  readonly " $data"?: BettingListBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"BettingListBlock_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BettingListBlock_block",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BettingListVariant",
      "kind": "LinkedField",
      "name": "bettingListVariant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "groupIds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "variant",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "KambiOffering",
      "kind": "LinkedField",
      "name": "kambiOffering",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "baseUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lang",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "market",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BettingListBlock",
  "abstractKey": null
};

(node as any).hash = "034e4b0ceda4405601676a30b1b72cc3";

export default node;
