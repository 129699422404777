/**
 * QuickButtons
 *
 * This will show buttons for quickly setting an amount in the Deposit Form.
 * It will base the options on the min / max deposit value from deposit config
 * as well as the last deposit by the user.
 */
import type { FC } from 'react';
import styled from '@emotion/styled';
import type { CurrencyCode } from '@pafcloud/locale';
import { getCurrencyCode } from '@pafcloud/locale';
import { Breakpoint, FontTextSize } from '@pafcloud/style';
import { NoStyleButton } from '@pafcloud/base-components';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { TransactionColors } from '../transaction-colors';
import { getDepositFormConfig } from './DepositFormConfig';

const QuickButtonsContainer = styled.div({
  display: 'grid',
  gridAutoFlow: 'column dense',
  gridGap: '16px',
  justifyContent: 'center',
});

const QuickButton = styled(NoStyleButton)({
  fontSize: FontTextSize.Normal,
  fontWeight: $buildEnv.theme === 'momentum' ? 900 : 'bold',

  padding: 0,
  color: `var(--quick-button-text, ${TransactionColors.QuickButtonText})`,
  position: 'relative',
  lineHeight: 1,

  ':hover, :active, :focus': {
    outline: 'none',
    color: `var(--quick-button-text--hover, ${TransactionColors.QuickButtonTextHover})`,

    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: -4,
      right: -4,
      bottom: -4,
      left: -4,
      borderRadius: 4,
      boxShadow: '0 0 0 1px currentColor',
    },
  },

  ':disabled': {
    opacity: 0.3,
  },
  [Breakpoint.TabletOrLarger]: {
    fontSize: FontTextSize.Big,
  },
});

type Props = {
  onSelect: (amount: number) => void;
  depositLimit?: number | null;
  quickValues?: Record<CurrencyCode, number[]>;
};

export const QuickButtons: FC<Props> = ({ onSelect, depositLimit, quickValues }) => {
  const { quickButtonValues } = getDepositFormConfig();

  const buttonValues = quickValues ? quickValues[getCurrencyCode()] : quickButtonValues;

  return (
    <QuickButtonsContainer>
      {buttonValues
        .sort((a, b) => a - b)
        .map((amount) => {
          const overLimit = Boolean(depositLimit && amount > depositLimit);

          return (
            <QuickButton
              key={amount}
              id={`QuickButtons_QuickButton_${amount}`}
              onClick={() => onSelect(amount)}
              disabled={overLimit}
            >
              {amount}
            </QuickButton>
          );
        })}
    </QuickButtonsContainer>
  );
};
