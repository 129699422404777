import { useEffect, useState } from 'react';
import { getCurrencyCode } from '@pafcloud/locale';
import { useHandler, usePoller } from '@pafcloud/react-hook-utils';

export type JackpotDrop = {
  key: string;
  name: string;
  time: {
    target: string | null;
  };
  amount: number;
  prevWinAmount: number;
};

export type JackpotResponse = {
  success: boolean;
  result: {
    jackpots: { pots: JackpotDrop[] }[];
  };
};

const POLL_INTERVAL = 5_000;

export const useJackpotDropPoller = () => {
  const [jackpotDrops, setJackpotDrops] = useState<JackpotDrop[] | null>(null);

  const { startPolling, stopPolling } = usePoller(POLL_INTERVAL);

  const startJackpotDropPolling = useHandler(async () => {
    startPolling(async () => {
      try {
        const jackpotUrl = `https://feed-paf.redtiger.cash/jackpots/?currency=${getCurrencyCode()}`;
        const response = await fetch(jackpotUrl);

        if (!response.ok) {
          setJackpotDrops(null);
          return false;
        }

        const jackpotResponse: JackpotResponse = await response.json();
        const [jackpot] = jackpotResponse.result.jackpots;

        if (typeof jackpot === 'undefined') {
          setJackpotDrops(null);
          return true;
        }

        setJackpotDrops(jackpot.pots);

        return true;
      } catch {
        return true;
      }
    });
  });

  useEffect(() => {
    void startJackpotDropPolling();
    return () => stopPolling();
  }, [startJackpotDropPolling, stopPolling]);

  return jackpotDrops;
};
