/**
 * @generated SignedSource<<8005f165b8993cbf6709b11c27c61815>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GamingFreezeIssuer = "HAMPI" | "PLAYER" | "SOURCE_OF_FUNDS" | "SOURCE_OF_WEALTH";
export type LaunchErrorCode = "BLOCKED" | "DEMO_UNAVAILABLE" | "MAINTENANCE" | "NICKNAME_MISSING" | "SPANISH_GAME_SESSION_SERVICE_ERROR" | "UNKNOWN";
export type PokerClient = "DOWNLOAD" | "WEB";
export type LaunchPokerInput = {
  client: PokerClient;
};
export type usePokerLaunchMutation$variables = {
  input: LaunchPokerInput;
};
export type usePokerLaunchMutation$data = {
  readonly launchPoker: {
    readonly __typename: "LaunchBlockedTurnoverCategory";
    readonly blockedCategory: string;
  } | {
    readonly __typename: "LaunchCategoryFrozen";
    readonly freeze: {
      readonly expires: string | null;
      readonly freezeIssuer: GamingFreezeIssuer;
    };
    readonly frozenCategory: string;
  } | {
    readonly __typename: "LaunchError";
    readonly error: LaunchErrorCode;
  } | {
    readonly __typename: "LaunchRequiredAgeNotMet";
    readonly ageRequirement: number;
  } | {
    readonly __typename: "PokerLaunch";
    readonly playerId: string;
    readonly popupUrl: string | null;
    readonly token: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type usePokerLaunchMutation = {
  response: usePokerLaunchMutation$data;
  variables: usePokerLaunchMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "launchPoker",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "playerId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "popupUrl",
            "storageKey": null
          }
        ],
        "type": "PokerLaunch",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "error",
            "storageKey": null
          }
        ],
        "type": "LaunchError",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ageRequirement",
            "storageKey": null
          }
        ],
        "type": "LaunchRequiredAgeNotMet",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "frozenCategory",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GamingFreeze",
            "kind": "LinkedField",
            "name": "freeze",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "freezeIssuer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expires",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "LaunchCategoryFrozen",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "blockedCategory",
            "storageKey": null
          }
        ],
        "type": "LaunchBlockedTurnoverCategory",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usePokerLaunchMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usePokerLaunchMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "782d14b1aeaa351172c73c2fe263a0e8",
    "id": null,
    "metadata": {},
    "name": "usePokerLaunchMutation",
    "operationKind": "mutation",
    "text": "mutation usePokerLaunchMutation(\n  $input: LaunchPokerInput!\n) {\n  launchPoker(input: $input) {\n    __typename\n    ... on PokerLaunch {\n      playerId\n      token\n      popupUrl\n    }\n    ... on LaunchError {\n      error\n    }\n    ... on LaunchRequiredAgeNotMet {\n      ageRequirement\n    }\n    ... on LaunchCategoryFrozen {\n      frozenCategory\n      freeze {\n        freezeIssuer\n        expires\n      }\n    }\n    ... on LaunchBlockedTurnoverCategory {\n      blockedCategory\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c4f7e2b0f31188e1c08bf9b4264f59eb";

export default node;
