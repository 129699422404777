import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { toast } from 'react-toastify';
import { parseISO } from 'date-fns';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { graphql, useFragment } from 'react-relay/hooks';
import { Button, Icon, ModalBackgroundOverlay, renderIntoRootContainer } from '@pafcloud/base-components';
import { useFormatAmount, useFormatDate } from '@pafcloud/react-hook-utils';
import { useBonus } from '@pafcloud/contexts';
import { Heading } from '@pafcloud/base-components';
import { Breakpoint, Color, Font, FontHeadingSize, FontTextSize, getBrandOverlay, ZIndex } from '@pafcloud/style';
import { useTranslation } from '@pafcloud/i18n';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { LoyaltyProgramColors } from '../loyalty-program-colors';
import { useClaimReward } from '../../bonus/hooks';
import { createAnimationWithFps } from '../../must-drop/useTween';
import type { LoyaltyProgramPayout_payout$key } from './__generated__/LoyaltyProgramPayout_payout.graphql';

const loyaltyProgramPayoutFragment = graphql`
  fragment LoyaltyProgramPayout_payout on LoyaltyProgramPlayer {
    estimatedCashback
    rewardPeriodEndsAt
  }
`;

const processCardAnimation = keyframes({
  from: { transform: 'translateY(100vw) scale(.1)' },
  to: { transform: 'translateY(0vw) scale(1)' },
});

const overlayAnimation = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
});

const growingNumberAnimation = keyframes({
  from: {
    transform: 'scale(0)',
  },
  to: {
    transform: 'scale(1)',
  },
});

const horizontalProgressAnimation = keyframes({
  from: {
    width: 0,
  },
  to: {
    width: '100%',
  },
});

const verticalProgressAnimation = keyframes({
  from: {
    height: 0,
  },
  to: {
    height: '100%',
  },
});

const Payout = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  margin: 0,
  padding: 24,

  background: LoyaltyProgramColors.ProcessCardBackground,
  borderRadius: 8,

  [Breakpoint.LaptopOrLarger]: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    textAlign: 'unset',

    'div:first-of-type': {
      marginRight: 'auto',
    },
  },
});

const PayoutHeading = styled(Heading)({
  margin: 0,
  fontSize: FontTextSize.Big,
  color: Color.HeadingText,
});

const PayoutAmount = styled.span({
  display: 'block',
  marginTop: 16,
  fontFamily: Font.Heading,
  fontSize: FontHeadingSize.Huge,
  fontWeight: 'bold',
  fontVariantNumeric: 'tabular-nums',
  color: LoyaltyProgramColors.TotalText,
  lineHeight: 1,

  [Breakpoint.LaptopOrLarger]: {
    margin: 0,
    marginRight: 16,
  },
});

const PayoutDate = styled.p({
  margin: 0,
  fontSize: FontTextSize.Small,
  color: LoyaltyProgramColors.Text,
});

const PayoutProcessCardOverlay = styled(ModalBackgroundOverlay)({
  zIndex: ZIndex.ProcessCard,
  ...getBrandOverlay(),
  overflow: 'hidden',
  animation: `${overlayAnimation} .2s ease`,
});

const ClaimButton = styled(Button)({
  marginTop: 16,

  [Breakpoint.LaptopOrLarger]: {
    margin: 0,
    marginRight: 16,
  },
});

const PayoutProcessCard = styled.div({
  position: 'absolute',
  top: 'calc(50% - 150px)',
  left: 'calc(50% - 150px)',
  width: 300,
  height: 200,
  borderRadius: 4,
  display: 'flex',
  justifyContent: 'center',
  background: LoyaltyProgramColors.ProcessCardBackground,
  animation: `${processCardAnimation} .2s ease forwards`,

  [`${PayoutAmount}`]: {
    animation: `${growingNumberAnimation} 1s ease-in forwards`,
  },

  '> span': {
    position: 'absolute',
    display: 'block',
    borderRadius: 4,
    background: LoyaltyProgramColors.ProcessCardProgress,
  },
  div: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    'span:last-of-type': {
      margin: '0 auto',

      svg: {
        color: Color.Primitive.Primary,
        background: LoyaltyProgramColors.ProcessCardBackground,
      },
    },
  },

  '.top, .bottom': {
    height: 4,
    animation: `${horizontalProgressAnimation} .8s linear forwards`,
  },
  '.left, .right': {
    width: 4,
    animation: `${verticalProgressAnimation} .8s linear forwards`,
  },

  '.top': {
    top: 0,
    left: 0,
  },
  '.right': {
    top: 0,
    right: 0,
    animationDelay: '.8s',
  },
  '.bottom': {
    bottom: 0,
    right: 0,
    animationDelay: '1.6s',
    borderRadius: '4px 0 0 4px',
  },
  '.left': {
    bottom: 0,
    left: 0,
    animationDelay: '2.4s',
  },
});

const shouldAnimateReward = (amount: number | undefined) => {
  if (amount == null) {
    return false;
  }

  if ($buildEnv.market === 'sweden') {
    return amount >= 100;
  }

  return amount >= 10;
};

type LoyaltyProgramPayoutProps = {
  loyaltyProgram: LoyaltyProgramPayout_payout$key | null;
};

export const LoyaltyProgramPayout: FC<LoyaltyProgramPayoutProps> = (props) => {
  const loyaltyProgram = useFragment(loyaltyProgramPayoutFragment, props.loyaltyProgram);
  const { estimatedCashback, rewardPeriodEndsAt } = loyaltyProgram ?? {};
  const { t } = useTranslation(['loyalty-program', 'common']);
  const { cashbackReward, refetchBonusContextQuery } = useBonus();
  const formatAmount = useFormatAmount();
  const formatDate = useFormatDate();
  const claimReward = useClaimReward();
  const intermediateAmount = useRef(0);

  const parsedRewardPeriodEndsAt = parseISO(rewardPeriodEndsAt ?? '');

  const [state, setState] = useState({
    shouldAnimate: false,
    isLoading: false,
    // The animation feels really weird when the sum is low, so this limits that.
    shouldUseAnimation: shouldAnimateReward(cashbackReward?.realAmount),
    rewardAmount: shouldAnimateReward(cashbackReward?.realAmount) ? 0 : (cashbackReward?.realAmount ?? 0),
  });

  const handleClaim = async () => {
    if (cashbackReward?.playerStepId == null) {
      toast.error(t('common:errors.generic'));
      return;
    }

    if (state.shouldUseAnimation) {
      setState((prev) => ({
        ...prev,
        shouldAnimate: true,
        isLoading: true,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        isLoading: true,
      }));
    }

    try {
      /**
       * Make sure the reward is claimed before initiating the animation.
       * Then stop the animation after the given time (3s) and at that point
       * update the Relay store. Wait for the store update to then update the
       * component state, which in turn forces a rerender of this component
       * and replaces the "Claim" button.
       */
      await claimReward({ playerStepId: cashbackReward.playerStepId });

      if (!state.shouldUseAnimation) {
        refetchBonusContextQuery(() =>
          setState((prev) => ({
            ...prev,
            isLoading: false,
          })),
        );
      }

      await new Promise((resolve) => setTimeout(resolve, 3000)).then(() => {
        refetchBonusContextQuery(() =>
          setState((prev) => ({
            ...prev,
            isLoading: false,
            shouldAnimate: false,
          })),
        );
      });
    } catch {
      setState((prev) => ({
        ...prev,
        isLoading: false,
        shouldAnimate: false,
      }));
      toast.error(t('common:errors.generic'));
      return;
    }
  };

  useEffect(() => {
    const { runAnimation, stopAnimation } = createAnimationWithFps(10);

    if (cashbackReward?.realAmount != null && state.shouldAnimate) {
      runAnimation((nextFrame) => {
        // This should be pretty quick, independently of the actual sum, 15 increments seems good
        const incrementAmount = cashbackReward.realAmount / 15;
        const nowValue = (intermediateAmount.current += incrementAmount);

        intermediateAmount.current = nowValue;

        if (nowValue < cashbackReward.realAmount) {
          setState((prev) => ({
            ...prev,
            rewardAmount: nowValue,
          }));

          nextFrame();
        }

        if (nowValue >= cashbackReward.realAmount) {
          setState((prev) => ({
            ...prev,
            rewardAmount: cashbackReward.realAmount,
          }));
          stopAnimation();
        }
      });
    }

    return () => {
      stopAnimation();
    };
  }, [cashbackReward, state]);

  if (cashbackReward != null) {
    return (
      <Payout>
        <div>
          <PayoutHeading>{t('progress.payout-ready')}</PayoutHeading>
          {cashbackReward.expires && (
            <PayoutDate>
              {t('progress.payout-expires', {
                expirationDateWithDay: formatDate(cashbackReward.expires, {
                  dateStyle: 'full',
                }),
              })}
            </PayoutDate>
          )}
        </div>

        {!state.shouldUseAnimation && <PayoutAmount>{formatAmount(state.rewardAmount)}</PayoutAmount>}
        <ClaimButton
          type="button"
          variant="primary"
          colorstyle="accent"
          icon="gift"
          iconAlignment="LEFT"
          size="large"
          ctaEffect
          onClick={handleClaim}
          isLoading={state.isLoading}
        >
          {t('progress.claim-reward')}
        </ClaimButton>

        {state.shouldUseAnimation &&
          state.shouldAnimate &&
          renderIntoRootContainer(
            <PayoutProcessCardOverlay>
              <PayoutProcessCard>
                <span className="top" />
                <span className="right" />
                <span className="bottom" />
                <span className="left" />
                <div>
                  <PayoutAmount>{formatAmount(state.rewardAmount)}</PayoutAmount>
                  <Icon name="coins" size="5rem" />
                </div>
              </PayoutProcessCard>
            </PayoutProcessCardOverlay>,
          )}
      </Payout>
    );
  }

  return (
    <Payout>
      <div>
        <PayoutHeading>{t('progress.next-payout')}</PayoutHeading>
        <PayoutDate>{formatDate(parsedRewardPeriodEndsAt, { dateStyle: 'full' })}</PayoutDate>
      </div>
      {estimatedCashback != null && <PayoutAmount>{formatAmount(estimatedCashback)}</PayoutAmount>}
    </Payout>
  );
};
