/**
 * @generated SignedSource<<59ee70b9d066551e03fb321b1c1e39bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NumberedGameListBlock_block$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NumberedGameList_block">;
  readonly " $fragmentType": "NumberedGameListBlock_block";
};
export type NumberedGameListBlock_block$key = {
  readonly " $data"?: NumberedGameListBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"NumberedGameListBlock_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NumberedGameListBlock_block",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NumberedGameList_block"
    }
  ],
  "type": "NumberedGameListBlock",
  "abstractKey": null
};

(node as any).hash = "f4ef62c268d40418f9617ffb5f89fb8c";

export default node;
