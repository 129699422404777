/**
 * @generated SignedSource<<a0d88620dd78df1ed1db21697e13e78c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PageBlockRenderer_blocks$data = ReadonlyArray<{
  readonly __typename: string;
  readonly " $fragmentSpreads": FragmentRefs<"AccordionBlock_block" | "BackgroundContainerBlock_block" | "BettingBlock_block" | "BettingClientBlock_block" | "BettingListBlock_block" | "BillboardGameListBlock_block" | "BonusTermsBlock_block" | "ContainerBlock_block" | "DepositFormBlock_block" | "GameShortcutBlock_block" | "GameplayGameListBlock_block" | "GridGameListBlock_block" | "HeroBlock_block" | "HighlightGameListBlock_block" | "IframeBlock_block" | "InformationContainerBlock_block" | "LinkContainerBlock_block" | "LoyaltyProgramDetailsBlock_block" | "LoyaltyProgramProgressBlock_block" | "MosaicGameListBlock_block" | "MultiplayerBingoListBlock_block" | "MustDropBlock_block" | "NumberedGameListBlock_block" | "PokerLauncherBlock_block" | "PosterGameListBlock_block" | "ProgramGroupBlock_block" | "PromotionsBlock_block" | "RichTextBlock_block" | "SearchBlock_block" | "TabGroupLinksBlock_block" | "TableBlock_block" | "TagFilterBlock_block">;
  readonly " $fragmentType": "PageBlockRenderer_blocks";
}>;
export type PageBlockRenderer_blocks$key = ReadonlyArray<{
  readonly " $data"?: PageBlockRenderer_blocks$data;
  readonly " $fragmentSpreads": FragmentRefs<"PageBlockRenderer_blocks">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "PageBlockRenderer_blocks",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccordionBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BackgroundContainerBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BettingBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BettingClientBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BettingListBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BonusTermsBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContainerBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DepositFormBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GameShortcutBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeroBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "IframeBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InformationContainerBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LinkContainerBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MultiplayerBingoListBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MustDropBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PokerLauncherBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProgramGroupBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PromotionsBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RichTextBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SearchBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TabGroupLinksBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TableBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TagFilterBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GameplayGameListBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PosterGameListBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillboardGameListBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NumberedGameListBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MosaicGameListBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GridGameListBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HighlightGameListBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LoyaltyProgramDetailsBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LoyaltyProgramProgressBlock_block"
    }
  ],
  "type": "Block",
  "abstractKey": "__isBlock"
};

(node as any).hash = "ede2d341eb3c6e166a7126902d0bcb9c";

export default node;
