import { useId } from 'react';
import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import styled from '@emotion/styled';
import shouldForwardProp from '@emotion/is-prop-valid';

import { Button, Card, Heading, Icon, Image, InternalLink } from '@pafcloud/base-components';
import { Color } from '@pafcloud/style';
import { camelCase } from '@pafcloud/util';
import { useGetFlowUrl } from '../flow/useGetFlowUrl';
import type { PromotionCard_promotion$key } from './__generated__/PromotionCard_promotion.graphql';
import { PROMOTION_CARD_BREAKPOINT } from './PromotionCardStyles';

const PromotionCardLink = styled(Card, { shouldForwardProp })<{ isLarge?: boolean }>(({ isLarge }) => ({
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'stretch',
  alignContent: 'stretch',
  transition: 'transform 0.1s ease-in-out',
  zIndex: 1, // ios14 hack to avoid content overflowing (even if it has overflow:hidden;)

  ':hover': {
    boxShadow: '0 1px 3px 0 rgba(34,40,41,0.2)',
  },

  [PROMOTION_CARD_BREAKPOINT]: {
    minWidth: 'unset',
    flexDirection: isLarge ? 'row' : 'column',
  },
})).withComponent(InternalLink);

const CardContent = styled.div<{ isLarge: boolean }>(({ isLarge }) => ({
  position: 'relative',
  zIndex: 2,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  minWidth: '50%',
  padding: 16,
  background: 'var(--card-background)',
  boxShadow: '0 0 12px rgba(0,0,0,0.3)',

  [PROMOTION_CARD_BREAKPOINT]: {
    flexDirection: isLarge ? 'column' : 'row',
  },
}));

const Text = styled.div<{ isLarge: boolean }>(({ isLarge }) => ({
  marginRight: 'auto',
  paddingRight: 12,
  color: Color.BodyText,

  [PROMOTION_CARD_BREAKPOINT]: {
    margin: isLarge ? 'auto' : undefined,
    textAlign: isLarge ? 'center' : 'left',
  },
}));

const Title = styled(Heading)<{ isLarge: boolean }>(({ isLarge }) => ({
  margin: '0',
  overflow: 'hidden',
  fontSize: isLarge ? `max(16px, min(0.5rem + 1.25vw, 28px))` : '1rem',

  color: 'inherit',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  lineHeight: '1.2em',
  textOverflow: 'ellipsis',
  textWrap: 'balance',
}));

const Details = styled.p({
  display: 'inline-flex',
  alignItems: 'center',
  margin: '0 0 4px 0',
  opacity: '0.75',
  fontSize: '0.75rem',
  color: 'inherit',

  // Icon
  svg: {
    marginRight: 4,
  },
});

const OpenButton = styled(Button)({
  flexShrink: 0,
  padding: '0 8px',
  marginLeft: 4,
  transition: 'padding 0.2s ease-in-out, margin-left 0.2s ease-in-out',

  'a:hover &': {
    margin: 0,
    padding: '0 10px',
  },
});

const Arrow = styled(Icon)({
  margin: '0 6px',
  fontSize: '1rem',
  transform: 'scaleX(-1)',

  'a:hover &': {
    stroke: 'currentColor',
    strokeWidth: 1,
    strokeLinecap: 'round',
    strokeDasharray: 0,
  },
});

const Figure = styled.figure({
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  aspectRatio: '16/9',
  flexBasis: '100%',
  margin: 0,

  // iOS 14 does not support aspect-ratio
  '@supports not (aspect-ratio: 16/9)': {
    '::before': {
      content: '""',
      float: 'left',
      height: 0,
      paddingTop: '56.25%', //16:9
    },
    '::after': {
      content: '""',
      display: 'table',
      clear: 'both',
    },
  },
});

const Media = styled(Image)({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,

  display: 'block',
  objectFit: 'cover',
  objectPosition: 'center center',
  width: '100%',
  height: '100%',
});

const IconBadge = styled.div({
  position: 'absolute',
  top: 8,
  left: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backdropFilter: 'blur(12px) brightness(80%) saturate(120%) contrast(102%)',
  background: 'rgba(42,42,42,0.0)',
  padding: '4px 8px',
  borderRadius: '99vw',

  fontSize: '0.75rem',
  fontWeight: 'bold',
  color: '#fff',

  // Icon
  svg: {
    marginRight: 4,
  },
});

const promotionFragment = graphql`
  fragment PromotionCard_promotion on PromotionCard {
    thumbnail
    sausage {
      iconName
      text
    }
    title
    details {
      iconName
      text
    }
    button {
      ariaLabel
      customLink
      ...useGetFlowUrl_button
    }
  }
`;

type CardSize = 'large' | 'small';

const sizes = (cardSize: CardSize) => {
  if (cardSize === 'large') {
    return `
      (max-width: 813px) 90vw,
      45vw,
    `;
  }

  return `
    (max-width: 500px) 90vw,
    (max-width: 813px) 35vw,
    37vw
  `;
};

type Props = {
  cardSize?: CardSize;
  promotion: PromotionCard_promotion$key;
};

export const PromotionCard: FC<Props> = ({ cardSize = 'small', ...props }) => {
  const { thumbnail, sausage, title, details, button } = useFragment(promotionFragment, props.promotion);
  const flowUrl = useGetFlowUrl({ data: button });
  const cardContentId = useId();
  const isLarge = cardSize === 'large';

  if (flowUrl == null) {
    return null;
  }

  return (
    <PromotionCardLink
      href={flowUrl}
      isLarge={isLarge}
      aria-label={button.ariaLabel ?? undefined}
      // If the aria-label is set this will be ignored, but if it is not set, a screen-reader
      // will read the contents of the <CardContent> element.
      aria-describedby={cardContentId}
    >
      <Figure>
        <Media src={thumbnail} width={320} height={180} sizes={sizes(cardSize)} />
        {sausage && (
          <IconBadge>
            {sausage.iconName && <Icon name={camelCase(sausage.iconName)} size="0.85em" />} {sausage.text}
          </IconBadge>
        )}
      </Figure>

      <CardContent isLarge={isLarge} id={cardContentId}>
        <Text isLarge={isLarge}>
          <Details>
            {details.iconName && <Icon name={camelCase(details.iconName)} size="1em" />}
            {details.text}
          </Details>
          <Title isLarge={isLarge}>{title}</Title>
        </Text>

        {button.customLink == null ? (
          <OpenButton variant="primary" size="small" type="button" tabIndex={-1}>
            <Arrow name="arrowLeftFat" size="1em" />
          </OpenButton>
        ) : (
          <OpenButton variant="secondary" size="small" type="button" tabIndex={-1}>
            <Arrow name="arrowLeftFat" size="1em" transform="rotate(45)" />
          </OpenButton>
        )}
      </CardContent>
    </PromotionCardLink>
  );
};
