/**
 * @generated SignedSource<<ba7d804ef561501b874e174aabb6a686>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeroBackground_background$data = {
  readonly image: {
    readonly url: string;
  } | null;
  readonly mobileImage: {
    readonly url: string;
  } | null;
  readonly mobileVideo: {
    readonly url: string | null;
  } | null;
  readonly video: {
    readonly url: string | null;
  } | null;
  readonly " $fragmentType": "HeroBackground_background";
};
export type HeroBackground_background$key = {
  readonly " $data"?: HeroBackground_background$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeroBackground_background">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeroBackground_background",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "mobileImage",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HeroVideo",
      "kind": "LinkedField",
      "name": "video",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HeroVideo",
      "kind": "LinkedField",
      "name": "mobileVideo",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "HeroBackground",
  "abstractKey": null
};
})();

(node as any).hash = "685b3beacba5d80ac2e4ef920404f229";

export default node;
