/**
 * @generated SignedSource<<5151df3788097995c00646365b34bc76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BettingBlockQuery$variables = {
  termKey: string;
};
export type BettingBlockQuery$data = {
  readonly sport: {
    readonly cardImage: {
      readonly url: string;
    };
  } | null;
};
export type BettingBlockQuery = {
  response: BettingBlockQuery$data;
  variables: BettingBlockQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "termKey"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "termKey",
    "variableName": "termKey"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "cardImage",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BettingBlockQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Sport",
        "kind": "LinkedField",
        "name": "sport",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BettingBlockQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Sport",
        "kind": "LinkedField",
        "name": "sport",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "052a9b353530e4bc0768280f4aecb13d",
    "id": null,
    "metadata": {},
    "name": "BettingBlockQuery",
    "operationKind": "query",
    "text": "query BettingBlockQuery(\n  $termKey: String!\n) {\n  sport(termKey: $termKey) {\n    cardImage {\n      url\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8c543e94b266be007227c452fe903572";

export default node;
