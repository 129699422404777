import type { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from '@pafcloud/i18n';
import { Icon } from '@pafcloud/base-components';
import { DropCountdown } from './DropCountdown';
import { TextContainer } from './shared';
import { MustDropColors } from './must-drop-colors';

const ClockIcon = styled(Icon)({
  display: 'flex',
  justifyContent: 'center',
  color: MustDropColors.TextHighlight,
  marginRight: 6,
});

const TimeContainer = styled.div({
  display: 'flex',
});

type Props = {
  time?: string | null;
};

export const WinBefore: FC<Props> = ({ time }) => {
  const { t } = useTranslation('jackpot');

  return (
    <TextContainer>
      {t('must-drop.win-before')}
      <TimeContainer>
        <ClockIcon name="clock" size="1.3rem" />
        {time ? <DropCountdown time={time} /> : null}
      </TimeContainer>
    </TextContainer>
  );
};
