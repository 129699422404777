/**
 * @generated SignedSource<<0168a12d1750323249694a5da9f4904a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BlockRenderer_blocks$data = ReadonlyArray<{
  readonly __typename: string;
  readonly " $fragmentSpreads": FragmentRefs<"BettingBlock_block" | "BettingClientBlock_block" | "BettingListBlock_block" | "BillboardGameListBlock_block" | "BonusTermsBlock_block" | "DepositFormBlock_block" | "GameShortcutBlock_block" | "GameplayGameListBlock_block" | "GridGameListBlock_block" | "HeroBlock_block" | "HighlightGameListBlock_block" | "IframeBlock_block" | "InformationContainerBlock_block" | "LinkContainerBlock_block" | "LoyaltyProgramDetailsBlock_block" | "LoyaltyProgramProgressBlock_block" | "MosaicGameListBlock_block" | "MultiplayerBingoListBlock_block" | "MustDropBlock_block" | "NumberedGameListBlock_block" | "PokerLauncherBlock_block" | "PosterGameListBlock_block" | "ProgramGroupBlock_block" | "PromotionsBlock_block" | "RichTextBlock_block" | "SearchBlock_block" | "TabGroupLinksBlock_block" | "TableBlock_block">;
  readonly " $fragmentType": "BlockRenderer_blocks";
}>;
export type BlockRenderer_blocks$key = ReadonlyArray<{
  readonly " $data"?: BlockRenderer_blocks$data;
  readonly " $fragmentSpreads": FragmentRefs<"BlockRenderer_blocks">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BlockRenderer_blocks",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BettingBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BettingClientBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BettingListBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BonusTermsBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DepositFormBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GameShortcutBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeroBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "IframeBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InformationContainerBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LinkContainerBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProgramGroupBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MultiplayerBingoListBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MustDropBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PokerLauncherBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PromotionsBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RichTextBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SearchBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TabGroupLinksBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TableBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GameplayGameListBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PosterGameListBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillboardGameListBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NumberedGameListBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MosaicGameListBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GridGameListBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HighlightGameListBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LoyaltyProgramDetailsBlock_block"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LoyaltyProgramProgressBlock_block"
    }
  ],
  "type": "Block",
  "abstractKey": "__isBlock"
};

(node as any).hash = "ffeb24e1c28b382407555c45ee0db22e";

export default node;
