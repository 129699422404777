import type { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Link, Icon, Card } from '@pafcloud/base-components';
import { Breakpoint, Color } from '@pafcloud/style';
import { useTranslation } from '@pafcloud/i18n';
import { BettingSportsIcon } from './BettingSportsIcon';
import type { BettingSport } from './useFetchKambi';
import { BettingColors } from './betting-colors';

const SportListContainer = styled.div({
  marginLeft: 'calc(var(--page-margin) * -1)',
  marginRight: 'calc(var(--page-margin) * -1)',
  overflow: 'hidden',
});

const SportLink = styled(Link)({
  display: 'block',
});

const IconCard = styled(Card)({
  position: 'relative',
  marginBottom: 8,
  width: '100%',
  paddingBottom: '100%',
});

const SportIcon = styled.div({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  padding: 8,
});

const KambiSportsIcon = styled(SportIcon)({}).withComponent(BettingSportsIcon);

const SportName = styled.span({
  display: 'block',
  width: '100%',
  minWidth: 0,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: Color.BodyText,
});

const AllSportsIcon = styled(SportIcon)({}).withComponent(Icon);

const Scroll = styled.div({
  scrollSnapType: 'x mandatory',
  scrollPadding: 'var(--page-margin)',
  overflowX: 'auto',
  overflowY: 'hidden',
  overscrollBehaviorInline: 'contain',

  '::-webkit-scrollbar': {
    display: 'none',
    width: 0,
    height: 0,
  },
});

const SportsGrid = styled.div({
  '--list-columns': 15,
  '--list-column-gap': '16px',
  '--total-gap': 'calc(var(--list-column-gap) * (var(--list-columns) - 1))',
  '--list-item-size': 'calc(((100% - var(--total-gap)) / var(--list-columns)))',

  display: 'grid',
  gridAutoFlow: 'column',
  gridAutoColumns: 64,
  gridTemplateRows: '1fr',
  gridAutoRows: 0,
  gap: '0 var(--list-column-gap)',

  marginRight: 'var(--page-margin)',
  marginLeft: 'var(--page-margin)',

  // dummy element for padding
  '::after': {
    content: '""',
    display: 'block',
    height: 10,
    width: 'var(--list-column-gap)',
  },

  [Breakpoint.TabletOrLarger]: {
    gridAutoFlow: 'unset',
    gridTemplateColumns: 'repeat(auto-fit, var(--list-item-size))',
    '--list-columns': 8,

    [`${SportLink}:last-of-type`]: {
      gridRow: '1',
      gridColumn: 'var(--list-columns)',
    },

    '::after': {
      display: 'none',
    },
  },

  [Breakpoint.LaptopOrLarger]: {
    '--list-columns': 10,
  },

  [Breakpoint.BigScreenOrLarger]: {
    justifyContent: 'center',
    '--list-columns': 15,
  },

  [Breakpoint.HDScreenOrLarger]: {
    maxWidth: 1366,
    margin: '0 auto',
    '--list-columns': 15,
  },
});

type KambiLinkProps = {
  path: string;
  name: string;
  children: ReactNode;
};

const KambiLink: FC<KambiLinkProps> = ({ path, name, children }) => {
  return (
    <SportLink href={`/betting${path}`} scroll={true}>
      <IconCard>{children}</IconCard>
      <SportName>{name}</SportName>
    </SportLink>
  );
};

type BettingSportListProps = {
  sports: BettingSport[];
};

export const BettingSportList: FC<BettingSportListProps> = ({ sports }) => {
  const { t } = useTranslation('betting');

  return (
    <SportListContainer>
      <Scroll>
        <SportsGrid>
          {sports.map((sport) => (
            <KambiLink key={sport.termKey} path={sport.path} name={sport.name}>
              <KambiSportsIcon termKey={sport.termKey} color={BettingColors.SportIcon} width={64} height={64} />
            </KambiLink>
          ))}
          <KambiLink path="#sports/a-z" name={t('sport-tile.all-sports')}>
            <AllSportsIcon size="100%" width="15%" name="kebab" color={BettingColors.SportIcon} />
          </KambiLink>
        </SportsGrid>
      </Scroll>
    </SportListContainer>
  );
};
