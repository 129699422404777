import type { FC } from 'react';
import styled from '@emotion/styled';
import type { Offering as ko } from '@pafcloud/kambi-offering';
import { Heading, HorizontalScroller, PrimaryHeading, Section } from '@pafcloud/base-components';
import { Breakpoint } from '@pafcloud/style';
import { BettingListCard } from './BettingListCard';
import { BettingCard } from './bettingListCardStyles';
import { useEventParticipantJerseys } from './useEventParticipantJerseys';

const BettingListScroller = styled(HorizontalScroller)({
  '--number-of-items': 1,

  [Breakpoint.TabletOrLarger]: {
    '--number-of-items': 1.33,
  },
  [Breakpoint.LaptopOrLarger]: {
    '--number-of-items': 2,
  },
  [Breakpoint.BigScreenOrLarger]: {
    '--number-of-items': 3,
  },
  [Breakpoint.HDScreenOrLarger]: {
    '--number-of-items': 4,
  },

  [`${BettingCard}`]: {
    flexGrow: 0,
  },
});

const BettingListHeader = styled.header({
  padding: '0 var(--full-width-margin) 12px',
});
const BettingListHeading = styled(Heading)(PrimaryHeading);

export type BettingListProps = {
  title: string | null;
  betOffers: ko.BetOffer[];
  events: ko.Event[];
  liveData?: ko.LiveData[];
};

export const BettingList: FC<BettingListProps> = ({ title, betOffers, events, liveData }) => {
  const participantJerseys = useEventParticipantJerseys(events);

  return (
    <>
      {title && (
        <BettingListHeader>
          <BettingListHeading>{title}</BettingListHeading>
        </BettingListHeader>
      )}
      <Section>
        <BettingListScroller>
          {betOffers.map((betOffer) => {
            const event = events.find((event) => event.id === betOffer.eventId);
            if (event == null) {
              return null;
            }

            const liveDataForEvent = liveData?.find((data) => data.eventId === event.id);

            return (
              <BettingListCard
                key={betOffer.id}
                betOffer={betOffer}
                event={event}
                liveData={liveDataForEvent}
                participantJersey={participantJerseys}
              />
            );
          })}
        </BettingListScroller>
      </Section>
    </>
  );
};
