import { useRouter } from 'next/router';
import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { GroupedTab, Link, ScrollableTabs, Tabs } from '@pafcloud/base-components';
import { isType } from '@pafcloud/collection-utils';
import { FullWidthTabLayout } from '../FullWidthTabLayout';
import type { TabGroupLinks_links$key } from './__generated__/TabGroupLinks_links.graphql';

const linksFragment = graphql`
  fragment TabGroupLinks_links on TabGroupLinksBlock {
    links {
      __typename
      ... on TabGroupBlockPageType {
        slug
        title
      }
      ... on TabGroupDropdown {
        dropdownTitle
        pages {
          slug
          title
        }
      }
    }
  }
`;

type TabGroupLinkLayoutProps = {
  links: TabGroupLinks_links$key;
};

export const TabGroupLinks: FC<TabGroupLinkLayoutProps> = (props) => {
  const { links } = useFragment(linksFragment, props.links);
  const router = useRouter();

  // strip initial slash and disregard any query string
  const slug = router.asPath.substring(1).split('?')[0];

  return (
    <FullWidthTabLayout>
      <ScrollableTabs>
        <Tabs>
          {links.map((link) => {
            if (isType(link, 'TabGroupDropdown')) {
              const childSlugMatchesPageSlug = link.pages.some((page) => page.slug === slug);

              return (
                <GroupedTab key={link.dropdownTitle} selected={childSlugMatchesPageSlug} title={link.dropdownTitle}>
                  {(close) => {
                    return link.pages.map((page) => {
                      const pageMatchesSlug = page.slug === slug;

                      return (
                        <li key={page.slug}>
                          <Link href={page.slug} aria-selected={pageMatchesSlug} onClick={close}>
                            {page.title}
                          </Link>
                        </li>
                      );
                    });
                  }}
                </GroupedTab>
              );
            }

            if (isType(link, 'TabGroupBlockPageType')) {
              const pageMatchesSlug = link.slug === slug;

              return (
                <Link key={link.slug} href={link.slug} role="tab" aria-selected={pageMatchesSlug}>
                  {link.title}
                </Link>
              );
            }

            return null;
          })}
        </Tabs>
      </ScrollableTabs>
    </FullWidthTabLayout>
  );
};
