import styled from '@emotion/styled';
import { Heading as AutoHeading, Card, Icon } from '@pafcloud/base-components';
import { Breakpoint, Color, Font, FontHeadingSize, FontTextSize } from '@pafcloud/style';

export type Layout = 'VERTICAL' | 'HORIZONTAL';

export const InfoCard = styled(Card)<{ layout: Layout }>(({ layout }) => ({
  display: 'inline-flex',
  justifySelf: 'center',
  width: 'min(360px, 100%)',
  minHeight: 320,
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px 24px',
  gap: 4,
  color: Color.BodyText,
  textAlign: 'center',
  flexDirection: 'column',

  [Breakpoint.TabletOrLarger]: {
    justifySelf: 'inherit',
    maxWidth: layout == 'VERTICAL' ? 320 : '100%',
    minWidth: layout == 'VERTICAL' ? 320 : '100%',
    minHeight: layout == 'VERTICAL' ? 320 : 160,
    justifyContent: layout === 'VERTICAL' ? 'center' : 'flex-start',
    textAlign: layout == 'VERTICAL' ? 'center' : 'left',
    flexDirection: layout == 'VERTICAL' ? 'column' : 'row',
  },
}));

export const ContainerIcon = styled(Icon)<{ layout: Layout }>(({ layout }) => ({
  marginBottom: 10,
  [Breakpoint.TabletOrLarger]: {
    marginBottom: layout == 'VERTICAL' ? 10 : 0,
    marginRight: layout == 'HORIZONTAL' ? 10 : 0,
  },
}));

export const ImageWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  minWidth: 150,
});

export const Heading = styled(AutoHeading)({
  margin: '0 0 16px 0',
  fontSize: FontHeadingSize.Small,
  fontFamily: Font.HeadingAlt,
  fontWeight: 'normal',
  lineHeight: 1.1,
  color: Color.HeadingText,
});

export const Text = styled.div<{ layout?: Layout }>(({ layout = 'VERTICAL' }) => ({
  maxWidth: 330,
  marginTop: 16,

  [Breakpoint.TabletOrLarger]: {
    maxWidth: layout === 'VERTICAL' ? 330 : 1000,
    marginTop: layout === 'VERTICAL' ? 16 : 0,
    marginLeft: layout === 'VERTICAL' ? undefined : 16,
    padding: layout === 'VERTICAL' ? undefined : '0 32px',
    borderLeft: layout === 'VERTICAL' ? 'unset' : `1px solid ${Color.Surface.Base.Dimmed}`,
  },
}));

export const Description = styled.p({
  margin: 0,
  fontSize: FontTextSize.Normal,
});
