import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import dynamic from 'next/dynamic';
import type { BettingClientBlock_block$key } from './__generated__/BettingClientBlock_block.graphql';

const blockFragment = graphql`
  fragment BettingClientBlock_block on BettingClientBlock {
    provider
  }
`;

const BettingClient = dynamic(() => import('@pafcloud/block-components').then((m) => m.BettingClient));
const IframedKambiBettingClient = dynamic(
  () => import('@pafcloud/block-components').then((m) => m.IframedKambiBettingClient),
  {
    ssr: false,
  },
);
const IframedScoutGamingBettingClient = dynamic(
  () => import('@pafcloud/block-components').then((m) => m.IframedScoutGamingBettingClient),
  {
    ssr: false,
  },
);

type Props = {
  block: BettingClientBlock_block$key;
};

const BettingClientBlock: FC<Props> = (props) => {
  const { provider } = useFragment(blockFragment, props.block);

  if (provider === 'KAMBI') {
    return <IframedKambiBettingClient />;
  }
  if (provider === 'SCOUT_GAMING') {
    return <IframedScoutGamingBettingClient />;
  }

  // Fallback to non-iframed betting client for providers without implementation
  return <BettingClient provider={provider} />;
};

export default BettingClientBlock;
