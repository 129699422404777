/**
 * @generated SignedSource<<7b4eb61d5b374d82ffcfed58e41a44c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HighlightGameListBlock_block$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HighlightGameList_block">;
  readonly " $fragmentType": "HighlightGameListBlock_block";
};
export type HighlightGameListBlock_block$key = {
  readonly " $data"?: HighlightGameListBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"HighlightGameListBlock_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HighlightGameListBlock_block",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HighlightGameList_block"
    }
  ],
  "type": "HighlightGameListBlock",
  "abstractKey": null
};

(node as any).hash = "c02cf2fe12bf7c4c1c395b3f2ccfd607";

export default node;
