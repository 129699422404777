import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import styled from '@emotion/styled';
import { Breakpoint } from '@pafcloud/style';
import { Heading, PrimaryHeading } from '@pafcloud/base-components';
import { useJackpotDropPoller } from './useJackpotDropPoller';
import { MustDropColors } from './must-drop-colors';
import { MinuteDrop } from './MinuteDrop';
import { DailyDrop } from './DailyDrop';
import { SuperDrop } from './SuperDrop';
import { DropType } from './dropType';

const MustDropSection = styled.section({
  padding: '0 var(--full-width-margin)',

  '& > * + *': {
    marginTop: '2rem',
  },
});

const Title = styled(Heading)(PrimaryHeading, {
  textAlign: 'center',
});

const MustDropContainer = styled.div({
  padding: '15px',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  position: 'relative',
  width: '100%',
  margin: 0,
  color: MustDropColors.Text,
  backgroundColor: MustDropColors.Background,
  boxShadow: MustDropColors.Shadow,
  borderRadius: 8,

  [Breakpoint.TabletOrLarger]: {
    display: 'grid',
    gridTemplateAreas: `"daily super" "daily minute"`,
    gridTemplateColumns: '1fr 1fr',
    gridGap: '20px 30px',
  },
});

type Props = {
  title: string | null;
  thumbnails: {
    minuteDrop: string;
    dailyDrop: string;
    superDrop: string;
  };
  onFetchSuccess: (show: boolean) => void;
  children?: ReactNode;
};

export const MustDrop: FC<Props> = ({ title, thumbnails, onFetchSuccess, children }) => {
  const jackpotDrops = useJackpotDropPoller();

  useEffect(() => {
    onFetchSuccess(jackpotDrops != null);
  }, [jackpotDrops, onFetchSuccess]);

  if (jackpotDrops == null) {
    return null;
  }

  return (
    <MustDropSection>
      {title && <Title>{title}</Title>}
      <MustDropContainer>
        {jackpotDrops.map((drop) => {
          if (drop.name === DropType.Minute) {
            return (
              <MinuteDrop
                thumbnail={thumbnails.minuteDrop}
                amount={drop.amount}
                time={drop.time.target}
                prevWinAmount={drop.prevWinAmount}
                key={drop.key}
              />
            );
          }

          if (drop.name === DropType.Daily) {
            return (
              <DailyDrop thumbnail={thumbnails.dailyDrop} amount={drop.amount} time={drop.time.target} key={drop.key} />
            );
          }

          if (drop.name === DropType.Super) {
            return <SuperDrop thumbnail={thumbnails.superDrop} amount={drop.amount} key={drop.key} />;
          }

          return null;
        })}
      </MustDropContainer>

      {children}
    </MustDropSection>
  );
};
