import type { FC } from 'react';
import type { StaticImageData } from 'next/image';
import { useRouter } from 'next/router';
import { intlFormat } from 'date-fns';
import type { LanguageCode } from '@pafcloud/locale';
import type { Offering as ko } from '@pafcloud/kambi-offering';
import { buildCoupon, formatOutcomes } from '@pafcloud/kambi-offering';
import { Image } from '@pafcloud/base-components';
import {
  BettingCard,
  EventHeader,
  EventLink,
  EventStart,
  EventStartDay,
  EventStartTime,
  EventSubTitle,
  EventTitle,
  LiveIcon,
  MatchScore,
  MatchSetPoints,
  MatchStats,
  OutcomeButton,
  OutcomeLabel,
  OutcomeOdds,
  Outcomes,
  Participant,
  ParticipantName,
} from './bettingListCardStyles';

type BettingListCardProps = {
  betOffer: ko.BetOffer;
  event: ko.Event;
  liveData?: ko.LiveData;
  participantJersey: Map<string, (StaticImageData & { altText: string | null }) | null>;
};

const EventState: FC<Pick<BettingListCardProps, 'event'>> = ({ event }) => {
  const router = useRouter();

  if (event.state === 'STARTED') {
    return <LiveIcon aria-hidden="true">LIVE</LiveIcon>;
  }

  if (event.state === 'NOT_STARTED') {
    const locale = (router.locale ?? router.defaultLocale) as LanguageCode;

    const eventStartDate = intlFormat(event.start, { month: 'long', day: 'numeric' }, { locale });
    const eventStartTime = intlFormat(event.start, { timeStyle: 'short' }, { locale });

    return (
      <EventStart dateTime={event.start}>
        <EventStartDay>{eventStartDate}</EventStartDay>
        <EventStartTime>{eventStartTime}</EventStartTime>
      </EventStart>
    );
  }

  return null;
};

export const BettingListCard: FC<BettingListCardProps> = ({ betOffer, event, liveData, participantJersey }) => {
  const participants = event.participants ?? [];
  const homeJersey = participantJersey.get(`${participants.find((p) => p.home)?.participantId.toString()}`);
  const awayJersey = participantJersey.get(`${participants.find((p) => !p.home)?.participantId.toString()}`);
  const showJerseys = homeJersey != null && awayJersey != null;

  const eventLink = `/betting#event/${event.id}`;

  const eventSport = event.path[0].name;
  const eventTitle = event.path[event.path.length - 1].name;

  const home = {
    jersey: showJerseys ? homeJersey : null,
    name: event.homeName,
    score: liveData?.score?.home,
    sets: liveData?.statistics?.sets?.home ?? [],
  };

  const away = {
    jersey: showJerseys ? awayJersey : null,
    name: event.awayName,
    score: liveData?.score?.away,
    sets: liveData?.statistics?.sets?.away ?? [],
  };

  return (
    <BettingCard aria-label={betOffer.betOfferType.name} key={event.id}>
      <EventLink href={eventLink} aria-label={event.name}>
        <EventHeader>
          <div>
            <EventSubTitle>{eventSport}</EventSubTitle>
            <EventTitle>{eventTitle}</EventTitle>
          </div>

          <EventState event={event} />
        </EventHeader>
        <Participant key={home.name}>
          {home.jersey && <Image src={home.jersey} width={32} alt={home.jersey.altText ?? ''} loading="lazy" />}

          <ParticipantName>{home.name}</ParticipantName>

          <MatchStats>
            {home.sets.map((set, index) => (
              <MatchSetPoints key={index}>{set == -1 ? 0 : set}</MatchSetPoints>
            ))}

            {home.score && <MatchScore>{home.score}</MatchScore>}
          </MatchStats>
        </Participant>

        <Participant key={away.name}>
          {away.jersey && <Image src={away.jersey} width={32} alt={away.jersey.altText ?? ''} loading="lazy" />}

          <ParticipantName>{away.name}</ParticipantName>

          <MatchStats>
            {away.sets.map((set, index) => (
              <MatchSetPoints key={index}>{set == -1 ? 0 : set}</MatchSetPoints>
            ))}

            {away.score && <MatchScore>{away.score}</MatchScore>}
          </MatchStats>
        </Participant>
      </EventLink>

      <Outcomes>
        {formatOutcomes(betOffer.outcomes).map((outcome) => {
          const coupon = buildCoupon('combination', outcome.id, '', 'append', '');
          const link = `${eventLink}?coupon=${coupon}`;
          return (
            <OutcomeButton
              key={outcome.id}
              aria-label={outcome.label}
              href={link}
              aria-disabled={outcome.status === 'SUSPENDED'}
            >
              <OutcomeLabel>{outcome.label}</OutcomeLabel>
              <OutcomeOdds>{outcome.odds ?? '-'}</OutcomeOdds>
            </OutcomeButton>
          );
        })}
      </Outcomes>
    </BettingCard>
  );
};
