import type { FC, ReactNode } from 'react';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { Button } from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import { getDateAndTime } from '@pafcloud/locale';
import { lowercase } from '@pafcloud/util';
import { logger } from '@pafcloud/logging';
import { isType } from '@pafcloud/collection-utils';
import { usePokerLaunch } from './usePokerLaunch';

type Props = {
  children?: ReactNode;
};

export const PokerLaunchButton: FC<Props> = ({ children }) => {
  const { t } = useTranslation('poker');
  const { locale } = useRouter();

  const [state, launchPoker] = usePokerLaunch((mutate) => ({
    onStart: () => {
      return mutate({ client: 'WEB' });
    },
    onResult: async (result) => {
      if (isType(result, 'PokerLaunch')) {
        const { popupUrl } = result;
        if (popupUrl == null) {
          logger.error('Web poker popup url missing.');
          toast.error(t('launcher.errors.unknown'));
          return;
        }

        window.open(popupUrl, '', 'width=800,height=600,left=0,top=0');
        return;
      }

      if (isType(result, 'LaunchError')) {
        if (result.error === 'DEMO_UNAVAILABLE') {
          toast.error(t('launcher.errors.not-logged-in'));
        } else {
          toast.error(t('launcher.errors.unknown'));
        }
      }

      if (isType(result, 'LaunchCategoryFrozen')) {
        const freeze = result.freeze;
        if (freeze.expires == null) {
          toast.error(t(`launcher.errors.frozen-${lowercase(freeze.freezeIssuer)}`));
        } else {
          const { date, time } = getDateAndTime(freeze.expires, locale);
          toast.error(t('launcher.errors.frozen', { date, time }));
        }
      }

      if (isType(result, 'LaunchBlockedTurnoverCategory')) {
        toast.error(t('launcher.errors.turnover'));
      }
    },
  }));

  return (
    <Button
      isLoading={state.status === 'pending'}
      type="button"
      size="default"
      variant="primary"
      onClick={() => launchPoker()}
    >
      {children}
    </Button>
  );
};
