/**
 * @generated SignedSource<<953f7e725cb26c8b187f554186a99786>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeroContentBlock_contentBlocks$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"HeroContentBlock_contentBlock">;
  readonly " $fragmentType": "HeroContentBlock_contentBlocks";
}>;
export type HeroContentBlock_contentBlocks$key = ReadonlyArray<{
  readonly " $data"?: HeroContentBlock_contentBlocks$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeroContentBlock_contentBlocks">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "HeroContentBlock_contentBlocks",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeroContentBlock_contentBlock"
    }
  ],
  "type": "HeroContentBlock",
  "abstractKey": "__isHeroContentBlock"
};

(node as any).hash = "4f4bdb9cb6b3cad88b86332736aede04";

export default node;
