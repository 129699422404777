import type { FC } from 'react';
import { useState } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { MustDrop } from '@pafcloud/block-components';
import { GameList } from '@pafcloud/game-components';
import type { MustDropBlock_block$key } from './__generated__/MustDropBlock_block.graphql';

const blockFragment = graphql`
  fragment MustDropBlock_block on MustDropBlock {
    title
    numberOfRows
    mustDropGameList {
      ...GameList_gameList
    }
    thumbnails {
      minuteDrop
      dailyDrop
      superDrop
    }
  }
`;

type Props = {
  block: MustDropBlock_block$key;
};

const MustDropBlock: FC<Props> = (props) => {
  const [showGameList, setShowGameList] = useState(false);
  const block = useFragment(blockFragment, props.block);
  const { title, numberOfRows, thumbnails, mustDropGameList } = block;

  return (
    <MustDrop title={title} thumbnails={thumbnails} onFetchSuccess={setShowGameList}>
      {showGameList && mustDropGameList && (
        <GameList title={null} gameList={mustDropGameList} trackable={null} numberOfRows={numberOfRows} />
      )}
    </MustDrop>
  );
};

export default MustDropBlock;
