import type { FC } from 'react';
import styled from '@emotion/styled';
import { Icon, Link } from '@pafcloud/base-components';
import { Breakpoint, Color } from '@pafcloud/style';
import type { Icon as IconType } from '@pafcloud/icon';

const CircleWrapper = styled(Link)({
  display: 'inline-flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',

  [Breakpoint.LaptopOrLarger]: {
    maxHeight: 320,
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '20px 24px',
  },
});

const Title = styled.strong({
  display: 'block',
  color: Color.BodyText,
});

const ShortcutIcon = styled(Icon)({
  minWidth: 40,
  justifyContent: 'center',
  marginBottom: 8,
});

type GameShortcutProps = {
  figureIcon?: string | null;
  link: {
    href: string;
    linkText: string;
    ariaLabel: string;
  };

  staticWidth?: boolean;
};

export const GameShortcut: FC<GameShortcutProps> = ({ link, figureIcon }) => {
  if (link == null) {
    return null;
  }

  return (
    <CircleWrapper href={link.href} aria-label={link.ariaLabel}>
      {figureIcon && <ShortcutIcon name={figureIcon as IconType} size="5rem" color={Color.BodyText} />}
      <Title>{link.linkText}</Title>
    </CircleWrapper>
  );
};
