/**
 * @generated SignedSource<<3a7b29104917d8fd7a8b1a73428cc7d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TabGroupLinksBlock_block$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TabGroupLinks_links">;
  readonly " $fragmentType": "TabGroupLinksBlock_block";
};
export type TabGroupLinksBlock_block$key = {
  readonly " $data"?: TabGroupLinksBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"TabGroupLinksBlock_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TabGroupLinksBlock_block",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TabGroupLinks_links"
    }
  ],
  "type": "TabGroupLinksBlock",
  "abstractKey": null
};

(node as any).hash = "12d6a0204e03dec0615ffe29315a6623";

export default node;
