/**
 * @generated SignedSource<<fc505640fe864f03f6ff854e8f016403>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoyaltyProgramDetailsBlock_block$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LoyaltyProgramLevelDetails_block">;
  readonly " $fragmentType": "LoyaltyProgramDetailsBlock_block";
};
export type LoyaltyProgramDetailsBlock_block$key = {
  readonly " $data"?: LoyaltyProgramDetailsBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"LoyaltyProgramDetailsBlock_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LoyaltyProgramDetailsBlock_block",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LoyaltyProgramLevelDetails_block"
    }
  ],
  "type": "LoyaltyProgramDetailsBlock",
  "abstractKey": null
};

(node as any).hash = "c3533f91387e06a56485084f7bb1677b";

export default node;
