/**
 * @generated SignedSource<<a2ee06cbf0301c689f03d82853e82553>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BlockLayout = "HORIZONTAL" | "VERTICAL";
import { FragmentRefs } from "relay-runtime";
export type LinkContainerBlock_block$data = {
  readonly ariaLabel: string | null;
  readonly blockLayout: BlockLayout;
  readonly description: string;
  readonly figure: {
    readonly altText: string | null;
    readonly icon: string | null;
    readonly src: string | null;
  };
  readonly heading: string;
  readonly href: string | null;
  readonly linkText: string | null;
  readonly " $fragmentType": "LinkContainerBlock_block";
};
export type LinkContainerBlock_block$key = {
  readonly " $data"?: LinkContainerBlock_block$data;
  readonly " $fragmentSpreads": FragmentRefs<"LinkContainerBlock_block">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LinkContainerBlock_block",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "heading",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blockLayout",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Figure",
      "kind": "LinkedField",
      "name": "figure",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "src",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "altText",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "icon",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "href",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "linkText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ariaLabel",
      "storageKey": null
    }
  ],
  "type": "LinkContainerBlock",
  "abstractKey": null
};

(node as any).hash = "3f50870b8db5320692aeaa970a845c00";

export default node;
